import React from 'react';
import { Text, View } from 'react-native';

import Tooltip from './Tooltip';

interface Props {
  children?: React.ReactNode;
}

const DisplayDisconnectedTooltip: React.FC<Props> = ({ children }) => {
  return (
    <Tooltip
      testID='display-disconnected-tooltip'
      accessibilityLabel='Display Disconnected Tooltip'
      drawPointer={false}
      content={
        <View className='w-44 space-y-5'>
          <Text className='font-[Lato-Bold] text-sm leading-5'>Display Disconnected</Text>
          <Text className='font-[Lato-Regular] text-sm leading-5'>
            This display cannot be reached. Contact your IT admin for help.
          </Text>
        </View>
      }>
      {children}
    </Tooltip>
  );
};

export default DisplayDisconnectedTooltip;
