import React from 'react';
import Animated, { useAnimatedProps } from 'react-native-reanimated';
import { Circle } from 'react-native-svg';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

interface Props {
  animation: Animated.SharedValue<number>;
  dot: number;
  ndots: number;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  color: string;
  width: number;
  onPress?: () => void;
}

const RouteLineDot: React.FC<Props> = ({
  animation,
  dot,
  ndots,
  x1,
  y1,
  x2,
  y2,
  color,
  width,
  onPress,
}) => {
  const props = useAnimatedProps(() => {
    return {
      cx:
        x1 === x2
          ? x1
          : x1 + ((animation.value * (x2 - x1) + ((x2 - x1) / ndots) * dot) % (x2 - x1)),
      cy:
        y1 === y2
          ? y1
          : y1 + ((animation.value * (y2 - y1) + ((y2 - y1) / ndots) * dot) % (y2 - y1)),
    };
  }, [x1, y1, x2, y2, dot, ndots]);

  return <AnimatedCircle animatedProps={props} r={width} fill={color} onPress={onPress} />;
};

export default RouteLineDot;
