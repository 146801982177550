import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import Menu from './Menu';

import LiveViewIcon from '../../assets/icons/live-view.svg';
import RouteToAllIcon from '../../assets/icons/route-to-all.svg';
import ShareScreenIcon from '../../assets/icons/share-screen.svg';
import UserOffIcon from '../../assets/icons/user-off.svg';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  visible: boolean;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  onOpen?: () => void;
  onClose?: () => void;
  onShareScreen?: () => void;
  onLivePreview?: () => void;
  onRouteToAll?: () => void;
  onDisconnectAllUsers?: () => void;
}

const DisplayMenu: React.FC<Props> = ({
  children,
  visible,
  top,
  bottom,
  left,
  right,
  onOpen,
  onClose,
  onShareScreen,
  onLivePreview,
  onRouteToAll,
  onDisconnectAllUsers,
}) => {
  return (
    <Menu
      testID='display-menu'
      flex
      visible={visible}
      onOpen={onOpen}
      onClose={onClose}
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      content={
        <View>
          <TouchableOpacity
            testID='route-to-all-button'
            className='flex-row space-x-2 border-b border-[#000]/20  p-1.5 hover:bg-black/5'
            onPress={onRouteToAll}>
            <RouteToAllIcon width={16} color='#20293c' />
            <Text className='font-[Lato-Regular] text-[#20293c] self-center'>Route to all</Text>
          </TouchableOpacity>
          <TouchableOpacity
            testID='view-display-button'
            className='flex-row space-x-2 border-b border-[#000]/20 p-1.5 hover:bg-black/5'
            onPress={onLivePreview}>
            <LiveViewIcon width={16} color='#20293c' />
            <Text className='font-[Lato-Regular] text-[#20293c] self-center'>View display</Text>
          </TouchableOpacity>
          <TouchableOpacity
            testID='share-screen-button'
            className='flex-row space-x-2 border-b border-[#000]/20 p-1.5 hover:bg-black/5'
            onPress={onShareScreen}>
            <ShareScreenIcon width={16} color='#20293c' />
            <Text className='font-[Lato-Regular] text-[#20293c] self-center'>
              Mirror screen to display
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            testID='disconnect-all-users-button'
            className='flex-row space-x-2 p-1.5 hover:bg-black/5'
            onPress={onDisconnectAllUsers}>
            <UserOffIcon width={16} color='#20293c' />
            <Text className='font-[Lato-Regular] text-[#20293c] self-center'>
              Disconnect all users
            </Text>
          </TouchableOpacity>
        </View>
      }>
      {children}
    </Menu>
  );
};

export default DisplayMenu;
