import React, { useCallback, useState } from 'react';
import { Platform, Pressable, StyleProp, TextInput, View, ViewStyle } from 'react-native';

import Form from './Form';

import EyeOffIcon from '../../assets/icons/eye-off.svg';
import EyeIcon from '../../assets/icons/eye.svg';

interface Props {
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  placeholder: string;
  placeholderTextColor: string;
  value?: string;
  onChangeText?: (value: string) => void;
  onSubmitEditing?: () => void;
  error?: boolean;
}

const PasswordInput: React.FC<Props> = ({
  placeholder,
  placeholderTextColor,
  value,
  onChangeText,
  onSubmitEditing,
  error = false,
  style,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [hasText, setHasText] = useState(false);

  const handleTextChange = useCallback(
    (text: string) => {
      onChangeText && onChangeText(text);
      if (text.length > 0 && !hasText) {
        setHasText(true);
      } else if (text.length === 0 && hasText) {
        setHasText(false);
      }
    },
    [hasText, setHasText, onChangeText]
  );

  return (
    <Form>
      <View
        style={style}
        className={`flex-row items-center justify-between rounded-md h-10 border-[1px] ${
          error ? 'bg-[#fff6f6] border-[#e0b4b4]' : 'bg-white border-[#d4d4d5]'
        } pl-4 pr-2 font-[Lato-Regular]`}>
        <TextInput
          testID='password-input'
          accessibilityLabel='Password'
          placeholder={placeholder}
          placeholderTextColor={placeholderTextColor}
          value={value}
          textAlignVertical='center'
          textContentType='password'
          onChangeText={handleTextChange}
          secureTextEntry={!passwordVisible}
          autoFocus={Platform.OS === 'web'}
          autoCorrect={false}
          autoComplete='password'
          returnKeyType='go'
          onSubmitEditing={() => onSubmitEditing?.()}
          className={`w-[90%] outline-none ${
            hasText ? 'font-[Lato-Bold]' : 'font-[Lato-Regular]'
          } ${error ? 'text-[#a1403e]' : 'text-black'}`}
        />
        <Pressable
          testID='password-visibility-button'
          accessibilityLabel='Password Visibility'
          onPress={() => {
            setPasswordVisible(!passwordVisible);
          }}>
          {passwordVisible ? (
            <EyeOffIcon
              width={22}
              height={21}
              viewBox='0 0 22 21'
              color={hasText ? 'black' : placeholderTextColor}
            />
          ) : (
            <EyeIcon
              width={22}
              height={16}
              viewBox='0 0 22 16'
              color={hasText ? 'black' : placeholderTextColor}
            />
          )}
        </Pressable>
      </View>
    </Form>
  );
};

export default PasswordInput;
