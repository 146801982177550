import { Color } from '@mersive/active-routing';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { LayoutChangeEvent, Text, View } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, { runOnJS, useAnimatedProps, useSharedValue } from 'react-native-reanimated';
import Svg, { Line as SvgLine } from 'react-native-svg';

import Display from './Display';
import DisplayMenu from './DisplayMenu';
import RouteLine from './RouteLine';

import useSpace from '../hooks/useSpace';
import { DisplayModel } from '../models/display';
import { getRouteId, Route } from '../models/route';

const AnimatedLine = Animated.createAnimatedComponent(SvgLine);

interface Props {
  rows: number;
  cols: number;
  spaceId: string;
  routes: Route[];
  onAddRoute?: (source: DisplayModel, sink: DisplayModel) => void;
  onRemoveRoute?: (routeIdToRemove: string) => void;
  onViewDisplay?: (display: DisplayModel) => void;
  onMirrorDisplay?: (display: DisplayModel) => void;
}

const DisplayGrid: React.FC<Props> = ({
  rows,
  cols,
  spaceId,
  routes,
  onAddRoute,
  onRemoveRoute,
  onViewDisplay,
  onMirrorDisplay,
}) => {
  const { data: space, status: spaceStatus, error: spaceError } = useSpace(spaceId);
  const [selectedDisplay, setSelectedDisplay] = useState<string | undefined>();
  const gridRef = useRef<View>(null);
  const [layout, setLayout] = useState<{ cellSize: number }>({ cellSize: 0 });
  const animatedLine = useSharedValue({ x1: 0, y1: 0, x2: 0, y2: 0, valid: false });
  const animatedLineProps = useAnimatedProps(() => {
    return {
      x1: animatedLine.value.x1,
      y1: animatedLine.value.y1,
      x2: animatedLine.value.x2,
      y2: animatedLine.value.y2,
    };
  });

  const displaySourceColors = useMemo(() => {
    const sources: { [key: string]: Color[] } = {};
    routes.forEach((line) => {
      if (!sources[line.sink.id]) {
        sources[line.sink.id] = [];
      }
      sources[line.sink.id].push(line.source.color);
    });
    return sources;
  }, [routes]);

  const handlePanStart = useCallback(
    (x: number, y: number) => {
      const cell = {
        row: Math.floor(y / layout.cellSize),
        col: Math.floor(x / layout.cellSize),
      };
      const display = space?.displays.find((d) => d.row === cell.row && d.col === cell.col);
      if (!display) return;
      console.log(`touching:`, display);
      // if (display.isLicensed === false || display.isReachable === false) return;
      // TODO: figure this out

      // find cell center
      const xc = display.col * layout.cellSize + layout.cellSize / 2;
      const yc = display.row * layout.cellSize + layout.cellSize / 2;

      animatedLine.value = { x1: xc, y1: yc, x2: xc, y2: yc, valid: true };
    },
    [animatedLine, space, layout]
  );

  const handlePanEnd = useCallback(
    (x: number, y: number) => {
      const x1 = animatedLine.value.x1;
      const y1 = animatedLine.value.y1;
      const x2 = x;
      const y2 = y;
      const from = {
        row: Math.floor(y1 / layout.cellSize),
        col: Math.floor(x1 / layout.cellSize),
      };
      const to = {
        row: Math.floor(y2 / layout.cellSize),
        col: Math.floor(x2 / layout.cellSize),
      };
      const sourceDisplay = space?.displays.find((d) => d.row === from.row && d.col === from.col);
      const sinkDisplay = space?.displays.find((d) => d.row === to.row && d.col === to.col);

      if (!sourceDisplay || !sinkDisplay || sourceDisplay.id === sinkDisplay.id) {
        return;
      }
      onAddRoute?.(sourceDisplay, sinkDisplay);
    },
    [onAddRoute, animatedLine, space, layout]
  );

  const gesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin((event) => {
          const { x, y } = event;
          runOnJS(handlePanStart)(x, y);
        })
        .onChange((event) => {
          if (!animatedLine.value.valid) return;
          const { x, y } = event;
          animatedLine.value = {
            x1: animatedLine.value.x1,
            y1: animatedLine.value.y1,
            x2: x,
            y2: y,
            valid: true,
          };
        })
        .onEnd((event) => {
          if (!animatedLine.value.valid) return;
          const { x, y } = event;
          runOnJS(handlePanEnd)(x, y);
          // on web, for some reason, the line is not cleared
          // if we set coords to 0, so had to set to an animated value
          animatedLine.value = {
            x1: animatedLine.value.x1,
            y1: animatedLine.value.y1,
            x2: animatedLine.value.x1,
            y2: animatedLine.value.y1,
            valid: false,
          };
        }),
    [animatedLine, handlePanStart, handlePanEnd]
  );

  const handleGridLayout = useCallback(
    (_e: LayoutChangeEvent) => {
      gridRef.current?.measureInWindow((_x, _y, width, height) => {
        if (width === 0 || height === 0) return;
        const size = Math.min(width / cols, height / rows);
        setLayout({ cellSize: size });
      });
    },
    [cols, rows]
  );

  const handleDisplayPress = useCallback((display: DisplayModel, open: boolean) => {
    // TODO: only show menu if display is online. For now leave it like this for
    // development purposes.
    if (open) {
      setSelectedDisplay(display.id);
    } else {
      setSelectedDisplay(undefined);
    }
  }, []);

  const handleLinePress = useCallback(
    (line: Route) => {
      const routeIdToRemove = getRouteId(line);
      onRemoveRoute?.(routeIdToRemove);
    },
    [onRemoveRoute]
  );

  if (spaceStatus === 'loading') {
    return (
      <View className='flex-1 flex-row items-center justify-items-stretch max-w-full'>
        <Text className='font-[Lato-Bold] text-2xl text-white self-center'>
          Loading Displays....
        </Text>
      </View>
    );
  }
  if (spaceStatus === 'error') {
    return (
      <View className='flex-1 flex-row items-center justify-items-stretch max-w-full'>
        <Text className='font-[Lato-Bold] text-2xl text-white self-center'>
          Error Loading Displays: {spaceError}
        </Text>
      </View>
    );
  }

  return (
    <View onLayout={handleGridLayout} className='flex-1 items-center justify-center'>
      <View
        style={{ aspectRatio: cols / rows }}
        className='flex-1 flex-row items-center justify-start max-w-full'>
        <GestureDetector gesture={gesture}>
          <View
            ref={gridRef}
            style={{ aspectRatio: cols / rows }}
            className='relative flex-1 max-h-full bg-green'>
            <Svg height='100%' width='100%' className='absolute top-0 left-0'>
              <AnimatedLine
                animatedProps={animatedLineProps}
                stroke='gray'
                strokeWidth='5'
                // TODO: remove onPress when we fix this issue:
                // https://github.com/software-mansion/react-native-svg/pull/1886
                onPress={() => {}}
              />
              {routes.map((line) => {
                const x1 = line.source.col * layout.cellSize + layout.cellSize / 2;
                const y1 = line.source.row * layout.cellSize + layout.cellSize / 2;
                const x2 = line.sink.col * layout.cellSize + layout.cellSize / 2;
                const y2 = line.sink.row * layout.cellSize + layout.cellSize / 2;
                const coords = { x1, y1, x2, y2 };
                return (
                  <RouteLine
                    key={getRouteId(line)}
                    onPress={() => {
                      handleLinePress(line);
                    }}
                    {...coords}
                    color={line.color}
                    isActive={line.isActive}
                    width={3}
                  />
                );
              })}
            </Svg>
            {space?.displays.map((display) => {
              let top;
              let bottom;
              let left;
              let right;

              if (display.row + 1 < (rows + 1) / 2) {
                top = 4;
              } else if (display.row + 1 > (rows + 1) / 2) {
                bottom = 4;
              }

              if (display.col + 1 < (cols + 1) / 2) {
                left = 0;
              } else if (display.col + 1 > (cols + 1) / 2) {
                right = 0;
              }

              return (
                <View
                  key={display.id}
                  style={{
                    width: `${(100 / cols) * 0.8}%`,
                    height: `${(100 / rows) * 0.8}%`,
                    top: `${(100 / rows) * display.row + (100 / rows) * 0.1}%`,
                    left: `${(100 / cols) * display.col + (100 / cols) * 0.1}%`,
                  }}
                  className='absolute'>
                  <DisplayMenu
                    visible={selectedDisplay === display.id}
                    top={top}
                    bottom={bottom}
                    left={left}
                    right={right}
                    onOpen={() => handleDisplayPress(display, true)}
                    onClose={() => handleDisplayPress(display, false)}
                    onLivePreview={() => {
                      handleDisplayPress(display, false);
                      onViewDisplay?.(display);
                    }}
                    onShareScreen={() => {
                      handleDisplayPress(display, false);
                      onMirrorDisplay?.(display);
                    }}>
                    <Display
                      id={display.id}
                      spaceId={spaceId}
                      sourceColors={displaySourceColors[display.id]}
                      disabled={selectedDisplay !== undefined && selectedDisplay !== display.id}
                    />
                  </DisplayMenu>
                </View>
              );
            })}
          </View>
        </GestureDetector>
      </View>
    </View>
  );
};

export default DisplayGrid;
