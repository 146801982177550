import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  Modal,
  Platform,
  Pressable,
  StatusBar,
  StyleProp,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export interface MenuProps {
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  children?: React.ReactNode | React.ReactNode[];
  flex?: boolean;
  testID?: string;
  accessibilityLabel?: string;
  visible: boolean;
  content: React.ReactNode;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  onOpen?: () => void;
  onClose?: () => void;
}

const Menu: React.FC<MenuProps> = ({
  style,
  children,
  flex = false,
  testID,
  accessibilityLabel,
  visible,
  content,
  top,
  bottom,
  left,
  right,
  onOpen,
  onClose,
}) => {
  const containerRef = useRef<View>(null);
  const insets = useSafeAreaInsets();
  const [menuLayout, setMenuLayout] = useState({ width: 10, height: 10 });
  const [layout, setLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    windowWidth: 0,
    windowHeight: 0,
  });

  const updateLayout = (windowWidth: number, windowHeight: number) => {
    containerRef.current?.measure((_x, _y, width, height, pageX, pageY) => {
      setLayout({ x: pageX, y: pageY, width, height, windowWidth, windowHeight });
    });
  };

  // After switching from native-stack to stack, the first `measure()` call started
  // returning incorrect values. This is a workaround to make sure the layout is
  // updated every time the menu visibility changes.
  useEffect(() => {
    if (layout.windowWidth > 0 && layout.windowHeight > 0) {
      updateLayout(layout.windowWidth, layout.windowHeight);
    }
  }, [visible, layout.windowWidth, layout.windowHeight]);

  useEffect(() => {
    const listener = Dimensions.addEventListener('change', ({ window, screen }) => {
      if (Platform.OS === 'web') {
        updateLayout(window.width, window.height);
      } else {
        updateLayout(screen.width, screen.height);
      }
    });
    return () => {
      listener.remove();
    };
  }, []);

  const handleLayout = useCallback(() => {
    const windowWidth = Platform.select({
      web: Dimensions.get('window').width,
      default: Dimensions.get('screen').width,
    });
    const windowHeight = Platform.select({
      web: Dimensions.get('window').height,
      android: Dimensions.get('window').height - (StatusBar.currentHeight || 0),
      default: Dimensions.get('screen').height + insets.bottom,
    });
    updateLayout(windowWidth, windowHeight);
  }, [insets]);

  const handleMenuLayout = useCallback((event: LayoutChangeEvent) => {
    const { width, height } = event.nativeEvent.layout;
    setMenuLayout({ width, height });
  }, []);

  const styles = useMemo(() => {
    const statusBarHeight = StatusBar.currentHeight || 0;
    let position: StyleProp<ViewStyle> = {};

    if (top !== undefined) {
      position.top = layout.y + layout.height + top + statusBarHeight;
    } else if (bottom !== undefined) {
      position.bottom = layout.windowHeight - layout.y + bottom;
    }

    if (left !== undefined) {
      position.left = layout.x + left;
    } else if (right !== undefined) {
      position.right = layout.windowWidth - layout.x - layout.width + right;
    }

    if (top === undefined && bottom === undefined) {
      // center vertically
      position.top = layout.y + layout.height / 2 - menuLayout.height / 2;
    }

    if (left === undefined && right === undefined) {
      // center horizontally
      position.left = layout.x + layout.width / 2 - menuLayout.width / 2;
    }

    return StyleSheet.create({
      menu: {
        position: 'absolute',
        ...position,
      },
    });
  }, [layout, menuLayout, bottom, left, right, top]);

  const invalidPosition =
    top === undefined && bottom === undefined && left === undefined && right === undefined;
  if (invalidPosition) {
    console.log('At least one of top, bottom, left, right must be defined');
  }

  return (
    <View
      className={flex ? 'flex-1' : ''}
      collapsable={false}
      ref={containerRef}
      onLayout={handleLayout}>
      <Pressable
        testID={testID}
        accessibilityLabel={accessibilityLabel}
        className={`select-none ${flex ? 'flex-1' : ''}`}
        onPress={() => onOpen && onOpen()}>
        {children}
      </Pressable>
      {invalidPosition ? null : (
        <Modal transparent visible={visible} animationType='fade'>
          <TouchableWithoutFeedback testID='menu-backdrop' onPress={() => onClose && onClose()}>
            <View className='flex-1 bg-black/10'>
              <TouchableWithoutFeedback onPress={() => {}}>
                <LinearGradient
                  onLayout={handleMenuLayout}
                  colors={['#ffffff', '#d4d4d5']}
                  style={[styles.menu, style]}
                  className='select-none absolute rounded-[10px]'>
                  {content}
                </LinearGradient>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      )}
    </View>
  );
};

export default Menu;
