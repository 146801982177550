import { useNavigation } from '@react-navigation/native';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback } from 'react';

import BasicPromptModal from './BasicPromptModal';
import ExitModal from './ExitModal';

import { Space } from '../models/space';
import activeRoutesAvailableAtom from '../store/activeRoutesAvailableAtom';
import { ssoAtom } from '../store/authAtom';
import exitSpacePromptAtom from '../store/exitSpacePromptAtom';
import { requireSpaceExitPasswordAtom } from '../store/settingsAtoms';

interface Props {
  space: Space | undefined;
}

const ExitSpaceModal: React.FC<Props> = ({ space }) => {
  const navigation = useNavigation();
  const [activeRoutes, setActiveRoutes] = useAtom(activeRoutesAvailableAtom);
  const [showExitSpacePrompt, setShowExitSpacePrompt] = useAtom(exitSpacePromptAtom);
  const { ssoEnabled } = useAtomValue(ssoAtom);
  const requireSpaceExitPassword = useAtomValue(requireSpaceExitPasswordAtom);

  const handleSubmit = useCallback(() => {
    setShowExitSpacePrompt('none');
    setActiveRoutes(false);
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.navigate('Home');
  }, [navigation, setActiveRoutes, setShowExitSpacePrompt]);

  return (
    <React.Fragment>
      {requireSpaceExitPassword && !ssoEnabled && (
        <ExitModal
          visible={showExitSpacePrompt === 'password'}
          title={activeRoutes ? 'Are you sure you want to exit?' : `Exit ${space?.name}`}
          passwordMessage='Enter your password to continue.'
          generalMessage={
            activeRoutes
              ? 'Exiting will remove active routes to displays within this space.'
              : undefined
          }
          submit='Exit'
          onClose={() => {
            setShowExitSpacePrompt('none');
          }}
          onSubmit={handleSubmit}
        />
      )}
      {(!requireSpaceExitPassword || ssoEnabled) && activeRoutes && (
        <BasicPromptModal
          visible={showExitSpacePrompt === 'simple'}
          title={activeRoutes ? 'Are you sure you want to exit?' : `Exit ${space?.name}`}
          message='Exiting will remove active routes to displays within this space.'
          submit='Exit'
          onClose={() => {
            setShowExitSpacePrompt('none');
          }}
          onSubmit={handleSubmit}
        />
      )}
    </React.Fragment>
  );
};

export default ExitSpaceModal;
