import { useSetAtom } from 'jotai';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Text } from 'react-native';

import ActionBar from '../components/ActionBar';
import DisplayGrid from '../components/DisplayGrid';
import DisplayPreviewModal from '../components/DisplayPreviewModal';
import ExitSpaceModal from '../components/ExitSpaceModal';
import SaveCustomPresetsModal from '../components/SaveCustomPresetsModal';
import Screen from '../components/Screen';
import ScreenMirror from '../components/ScreenMirror';
import useActiveRouting from '../hooks/useActiveRouting';
import useAnalytics from '../hooks/useAnalytics';
import useSpace from '../hooks/useSpace';
import useToast from '../hooks/useToast';
import { DisplayModel } from '../models/display';
import { getRouteId, Route } from '../models/route';
import { RootStackScreenProps } from '../navigation/types';
import activeRoutesAvailableAtom from '../store/activeRoutesAvailableAtom';

interface Props extends RootStackScreenProps<'Space'> {}

const SpaceScreen: React.FC<Props> = ({ navigation, route }) => {
  const { trackEvent } = useAnalytics();
  const { id: spaceId } = route.params;
  const { data: spaceData, status: spaceStatus, error: spaceError } = useSpace(spaceId);
  const toast = useToast();
  const { connect, disconnect, disconnectAll, displays, message, connections } =
    useActiveRouting(spaceId);
  const setActiveRoutes = useSetAtom(activeRoutesAvailableAtom);

  const [savePresetVisible, setSavePresetVisible] = useState(false);
  const [toastId, setToastId] = useState<string>('');
  const [liveDisplay, setLiveDisplay] = useState<DisplayModel | undefined>();
  const [mirrorDisplay, setMirrorDisplay] = useState<DisplayModel | undefined>();

  useEffect(() => {
    setActiveRoutes(connections.length > 0);
  }, [connections, setActiveRoutes]);

  useLayoutEffect(() => {
    navigation.setOptions({ title: spaceData?.name });
  }, [navigation, spaceData]);

  const handleClearMessage = useCallback(() => {
    // send the message through the AR API
    displays.forEach((display) => {
      message(display, '');
    });
  }, [message, displays]);

  const handleSendMessage = useCallback(
    (messageToSend: string) => {
      console.log(`Sending a message to the Displays: message=[${messageToSend}]`);
      if (toastId.length > 0) toast.hide(toastId);
      displays.forEach((display) => {
        message(display, messageToSend);
      });
      setToastId(
        toast.show('You are currently sending a message to all displays', {
          dismiss: 'Clear message',
          onDismiss: () => {
            handleClearMessage();
          },
        })
      );
    },
    [toastId, toast, displays, message, handleClearMessage]
  );

  const [routes, setRoutes] = useState<Route[]>([]);

  const handleAddRoute = useCallback(
    (source: DisplayModel, sink: DisplayModel) => {
      const newRoute: Route = {
        source,
        sink,
        color: source.color,
        isActive: false,
      };

      const newRouteId = getRouteId(newRoute);
      if (routes.some((line) => getRouteId(line) === newRouteId)) {
        console.warn(
          `Preventing creation of duplicate route: source=[${source.label}] sink=[${sink.label}]`
        );
        return;
      }

      console.log(`Creating Route: source=[${source.label}] sink=[${sink.label}]`);
      connect(source, sink).then(
        () => {
          console.debug(`Success creating Route: source=[${source.label}] sink=[${sink.label}]`);
          newRoute.isActive = true;
          // because we're simply changing a value inside of the state, and a notification
          // wouldn't occur otherwise, let's fully rewrite the state. this is terrible. there
          // must be a better way.
          setRoutes((oldRoutes) => [...oldRoutes]);
        },
        (error) => {
          console.error(`Failed to create connection:`, error);
          return;
        }
      );

      setRoutes((curr) =>
        [...curr, newRoute].sort((a, b) => {
          const distA = Math.abs(a.source.row - a.sink.row) + Math.abs(a.source.col - a.sink.col);
          const distB = Math.abs(b.source.row - b.sink.row) + Math.abs(b.source.col - b.sink.col);
          return distB - distA;
        })
      );
    },
    [routes, connect]
  );

  const handleRemoveRoute = useCallback(
    (routeIdToRemove: string) => {
      console.debug(`Attempting to remove Route: routeIdToRemove=[${routeIdToRemove}]`);
      const routeToRemove = routes.find((r) => getRouteId(r) === routeIdToRemove);
      if (!routeToRemove) {
        console.error(`failed to find route to remove:`, routeIdToRemove, routes);
        return;
      }

      console.log(
        `Removing route: source=[${routeToRemove.source.label}] sink=[${routeToRemove.sink.label}]`
      );

      disconnect(routeToRemove.source, routeToRemove.sink).then(
        () => {
          console.debug(
            `Success terminating Route: source=[${routeToRemove.source.label}] sink=[${routeToRemove.sink.label}]`
          );
          setRoutes((curr) => curr.filter((r) => getRouteId(r) !== routeIdToRemove));
        },
        (error) => {
          console.log(`Failed to remove connection:`, error);
          return;
        }
      );
    },
    [routes, disconnect]
  );

  const handleResetRoutes = useCallback(async () => {
    console.log(`Clearing all active Routes`);

    spaceData?.displays.forEach(async (display) => {
      await disconnectAll(display);
    });

    setRoutes([]);
  }, [disconnectAll, spaceData]);

  const handlePrimaryToAll = useCallback(async () => {
    console.log(`Routing Primary to all Displays`);
    const primaryDisplay = displays.find((display) => {
      return display.primary;
    });

    if (!primaryDisplay) {
      console.error(`Couldn't find a Primary Display in the Space. This shouldn't happen!!`);
      // log maybe to Mixpanel as well.
      return;
    }

    // Clear all other existing Routes first.
    await handleResetRoutes();

    // Create a Route from each Display to the Primary.
    displays.forEach((display) => {
      if (display.primary) return;
      handleAddRoute(primaryDisplay, display);
    });

    // send an event to mixpanel, including the number of displays
    // that are being shared to from the Primary.
    trackEvent(`primaryToAll`, { numberOfSinks: `${displays.length - 1}` });
  }, [displays, handleAddRoute, handleResetRoutes, trackEvent]);

  const handleAllToPrimary = useCallback(async () => {
    console.log(`Routing all Displays to the Primary Display`);
    const primaryDisplay = displays.find((display) => {
      return display.primary;
    });

    if (!primaryDisplay) {
      console.error(`Couldn't find a Primary Display in the Space. This shouldn't happen!!`);
      // log maybe to Mixpanel as well.
      return;
    }

    // Clear all other existing Routes first.
    await handleResetRoutes();
    displays.forEach((display) => {
      if (display.primary) return;
      handleAddRoute(display, primaryDisplay);
    });

    // send an event to mixpanel, including the number of displays
    // which sharing to the Primary.
    trackEvent(`allToPrimary`, { numberOfSources: `${displays.length - 1}` });
  }, [displays, handleAddRoute, handleResetRoutes, trackEvent]);

  const handleActivatePreset = useCallback(
    (presetId: string) => {
      console.log(`Activating a preset: ${presetId}`);
      handleResetRoutes();
      const preset = spaceData?.customPresets.find((c) => c.id === presetId);
      if (!preset) {
        // This shouldn't happen.
        console.error(`Couldn't find preset: id=[${presetId}]`);
        return;
      }
      for (const thisRoute of preset.routes) {
        handleAddRoute(thisRoute.source, thisRoute.sink);
      }
    },
    [spaceData, handleResetRoutes, handleAddRoute]
  );

  const handleSavePreset = useCallback(() => {
    console.log(`Showing Save Preset Modal`);
    setSavePresetVisible(true);
  }, [setSavePresetVisible]);

  const handlePresetNameSelected = useCallback(
    (presetName: string) => {
      console.log(`Saving current routes as a custom preset named [${presetName}]`);
      spaceData?.customPresets.push({
        id: presetName,
        label: presetName,
        routes,
      });
    },
    [spaceData, routes]
  );

  if (spaceStatus === 'loading') {
    return (
      <Screen>
        <Text>Loading Space</Text>
      </Screen>
    );
  }

  if (spaceStatus === 'error') {
    return (
      <Screen>
        <Text>Error Loading Space: {spaceError}</Text>
      </Screen>
    );
  }

  return (
    <Screen>
      <DisplayGrid
        rows={spaceData!.rows}
        cols={spaceData!.columns}
        spaceId={spaceData!.id}
        routes={routes}
        onAddRoute={handleAddRoute}
        onRemoveRoute={handleRemoveRoute}
        onViewDisplay={setLiveDisplay}
        onMirrorDisplay={setMirrorDisplay}
      />
      <ActionBar
        presets={spaceData!.customPresets}
        savePresetPromptVisible={savePresetVisible}
        onActivatePreset={handleActivatePreset}
        onSavePreset={handleSavePreset}
        onSendMessage={handleSendMessage}
        onPrimaryToAll={handlePrimaryToAll}
        onAllToPrimary={handleAllToPrimary}
        onResetRoutes={handleResetRoutes}
      />
      <SaveCustomPresetsModal
        visible={savePresetVisible}
        onClose={() => setSavePresetVisible(false)}
        onSubmit={handlePresetNameSelected}
        currentPresetCount={spaceData!.customPresets.length}
        maxPresetCount={10}
      />
      <ExitSpaceModal space={spaceData} />
      {liveDisplay !== undefined ? (
        <DisplayPreviewModal
          visible
          name={liveDisplay.label}
          color={liveDisplay.color}
          address={liveDisplay.ip}
          onClose={() => {
            setLiveDisplay(undefined);
          }}
        />
      ) : null}
      {mirrorDisplay !== undefined ? (
        <ScreenMirror display={mirrorDisplay} onClose={() => setMirrorDisplay(undefined)} />
      ) : null}
    </Screen>
  );
};

export default SpaceScreen;
