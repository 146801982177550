export type LookinErrorType =
  | 'disabled' // Lookin is disabled on the device
  | 'screenKey' // Missing screen key
  | 'network' // Network error
  | 'unknown'; // Unknown error

export class LookinError extends Error {
  type: string;

  constructor(type: LookinErrorType) {
    switch (type) {
      case 'disabled':
        super('Preview is disabled on the device.\nPlease enable the Look-In feature.');
        break;
      case 'screenKey':
        super('Please enter the correct screen key.');
        break;
      case 'network':
        super('Network error.\nPlease check your connection and try again.');
        break;
      default:
        super('Unknown error.\nPlease try again.');
    }
    this.name = 'LookinError';
    this.type = type;
  }
}
