import { useAtomValue } from 'jotai';
import React, { useCallback, useState } from 'react';
import { KeyboardAvoidingView, Platform, Text, View } from 'react-native';

import MersiveLogo from '../../assets/images/mersive-logo.svg';
import SolsticeRoutingLogo from '../../assets/images/solstice_routing_logo_white.svg';
import LoginEmailForm from '../components/LoginEmailForm';
import LoginPasswordForm from '../components/LoginPasswordForm';
import LoginRecoveryForm from '../components/LoginRecoveryForm';
import Screen from '../components/Screen';
import useAnalytics from '../hooks/useAnalytics';
import useAuthentication, { AuthError } from '../hooks/useAuthentication';
import useToast from '../hooks/useToast';
import { RootStackScreenProps } from '../navigation/types';
import { ssoAtom, usernameAtom } from '../store/authAtom';

type AuthPhase = 'email' | 'password' | 'recover';

type AuthState = {
  phase: AuthPhase;
  error?: string;
};

interface Props extends RootStackScreenProps<'Login'> {}

const LoginScreen: React.FC<Props> = () => {
  const [authState, setAuthState] = useState<AuthState>({ phase: 'email' });
  const { submitEmail, login, loginWithSSO, resetPassword } = useAuthentication();
  const { ssoEnabled } = useAtomValue(ssoAtom);
  const username = useAtomValue(usernameAtom);
  const { trackEvent } = useAnalytics();
  const toast = useToast();

  const handleLogin = useCallback(
    async (email: string, password: string, sso: boolean) => {
      try {
        if (sso) {
          await loginWithSSO(email);
        } else {
          await login(email, password);
        }
        trackEvent(`Login event`);
      } catch (err: any) {
        switch (err.type) {
          case 'credentials':
            setAuthState((curr) => ({
              ...curr,
              error: `The credentials you've entered did not match our records.\nPlease double-check and try again.`,
            }));
            break;
          case 'network':
            setAuthState((curr) => ({
              ...curr,
              error:
                'There was a problem logging in to your account.\nPlease check your network connection and try again.',
            }));
            break;
          default:
            setAuthState((curr) => ({
              ...curr,
              error: 'There was a problem logging in to your account.\nPlease try again.',
            }));
        }
      }
    },
    [trackEvent, loginWithSSO, login]
  );

  const handleEmailSubmit = useCallback(
    async (email: string, remember: boolean) => {
      try {
        const ssoStatus = await submitEmail(email, remember);
        if (ssoStatus.ssoOnly) {
          handleLogin(email, '', true);
        } else {
          setAuthState({ phase: 'password' });
        }
      } catch (err) {
        setAuthState((curr) => ({
          ...curr,
          error: 'Please check your network connection and try again.',
        }));
      }
    },
    [submitEmail, handleLogin]
  );

  const handlePasswordReset = useCallback(
    async (email: string) => {
      try {
        await resetPassword(email);
        toast.show('Check your inbox for a link to reset your password.', {
          type: 'info',
          timeout: 10000,
        });
        setAuthState({ phase: 'password' });
      } catch (err: any) {
        if (err instanceof AuthError) {
          setAuthState((curr) => ({
            ...curr,
            error:
              'There was a problem resetting your password.\nPlease check your network connection and try again.',
          }));
        }
      }
    },
    [resetPassword, toast]
  );

  return (
    <Screen>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        className='flex-1'>
        <View className='h-[45%] w-full items-center justify-end'>
          <View style={{ aspectRatio: 1000 / 264 }} className='w-11/12 max-w-lg max-h-36 mb-16'>
            <SolsticeRoutingLogo width='100%' height='100%' />
          </View>
        </View>
        <View className='h-[55%] w-full items-center'>
          <View className='w-11/12 max-w-sm items-center'>
            {authState.phase === 'email' && (
              <LoginEmailForm
                email={username}
                onSubmit={(email: string, remember: boolean) => {
                  handleEmailSubmit(email, remember);
                }}
              />
            )}
            {authState.phase === 'password' && (
              <LoginPasswordForm
                email={username}
                ssoEnabled={ssoEnabled ?? false}
                onBack={() => setAuthState({ phase: 'email' })}
                onForgotPassword={() => setAuthState({ phase: 'recover' })}
                onSubmit={(password: string, sso: boolean) => {
                  handleLogin(username, password, sso);
                }}
              />
            )}
            {authState.phase === 'recover' && (
              <LoginRecoveryForm
                email={username}
                onBack={() => setAuthState({ phase: 'password' })}
                onSubmit={handlePasswordReset}
              />
            )}
            <View className='mt-4'>
              <Text className='font-[Lato-Bold] text-[#ff3367] text-sm text-center'>
                {authState.error}
              </Text>
            </View>
          </View>
          <View className='flex-1 w-11/12 max-w-sm items-center justify-end pb-4'>
            <View style={{ aspectRatio: 150 / 56 }} className='w-[33%] max-h-full'>
              <MersiveLogo width='100%' height='100%' />
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Screen>
  );
};

export default LoginScreen;
