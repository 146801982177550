import React, { useCallback } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import Modal, { ModalProps } from './Modal';

interface Props extends ModalProps {
  title: string;
  message: string;
  submit: string;
  onSubmit?: () => void;
}

const BasicPromptModal: React.FC<Props> = ({
  visible,
  title,
  message,
  submit,
  onSubmit,
  onClose,
}) => {
  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit();
    handleClose();
  }, [onSubmit, handleClose]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <View className='w-[380px]'>
        <Text className='font-[Lato-Bold] text-lg text-center mb-[17px]'>{title}</Text>
        <Text className='font-[Lato-Regular] text-xs text-center mb-[19px]'>{message}</Text>
        <View className='flex-row items-center justify-center w-full'>
          <TouchableOpacity
            testID='modal-cancel-button'
            className='w-1/4 mx-3'
            onPress={handleClose}>
            <View className='rounded-[4px] py-1 border border-[#ff3367] bg-white'>
              <Text className='font-[Lato-Bold] text-sm text-[#ff3367] text-center'>Cancel</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            testID='modal-submit-button'
            className='w-1/4 mx-3'
            onPress={handleSubmit}>
            <View className='rounded-[4px] py-1 bg-[#ff3367] border border-[#ff3367]'>
              <Text className='font-[Lato-Bold] text-sm text-white text-center'>{submit}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default BasicPromptModal;
