import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TextInput, View } from 'react-native';

import ScreenKeyCell from './ScreenKeyCell';

interface Props {
  count: number;
  autoFocus?: boolean;
  onSubmit?: (key: string) => void;
}

const ScreenKeyInput: React.FC<Props> = ({ count, autoFocus = true, onSubmit }) => {
  const cellRefs = useRef<TextInput[]>([]);
  const [screenKey, setScreenKey] = useState<string[]>(Array(count).fill(''));

  useEffect(() => {
    if (screenKey.join('').trim().length === count) {
      onSubmit && onSubmit(screenKey.join('').trim());
      // reset state
      setScreenKey(Array(count).fill(''));
      cellRefs.current[0].focus();
    }
  }, [count, screenKey, onSubmit]);

  useLayoutEffect(() => {
    if (autoFocus) {
      cellRefs.current[0].focus();

      // For some reason autofocus stopped working on web. This is a workaround.
      // TODO: figure out why autofocus stopped working and fix it properly.
      setTimeout(() => {
        cellRefs.current[0].focus();
      }, 300);
    }
  }, [autoFocus]);

  const setKeyValue = (id: number, key: string) => {
    setScreenKey((prev) => {
      const next = [...prev];
      next[id] = key;
      return next;
    });
  };

  const handleKeyPress = useCallback(
    (id: number, key: string) => {
      const target = cellRefs.current[id];
      if (id < 0 || id > count || !target) return;

      switch (key) {
        case 'Backspace':
          if (screenKey[id]?.length) {
            setKeyValue(id, '');
          } else if (id > 0) {
            setKeyValue(id - 1, '');
            cellRefs.current[id - 1].focus();
          }
          break;
        default:
          if (key.match(/^[0-9]$/)) {
            setKeyValue(id, key);
            if (id + 1 < count) {
              cellRefs.current[id + 1].focus();
            }
          }
          break;
      }
    },
    [count, screenKey]
  );

  return (
    <View className='flex-row space-x-5'>
      {screenKey.map((v, i) => (
        <ScreenKeyCell
          inputRef={(el) => (el ? cellRefs.current.push(el) : null)}
          key={i}
          id={i}
          value={v}
          onKeyPress={handleKeyPress}
        />
      ))}
    </View>
  );
};

export default ScreenKeyInput;
