import { useAtom } from 'jotai';
import React from 'react';

import BasicPromptModal from './BasicPromptModal';
import ExitModal from './ExitModal';

import activeRoutesAvailableAtom from '../store/activeRoutesAvailableAtom';

interface Props {
  promptType: 'none' | 'simple' | 'password';
  onClose: () => void;
  onSubmit: () => void;
}

const LogoutModal: React.FC<Props> = ({ promptType, onClose, onSubmit }) => {
  const [activeRoutes, setActiveRoutes] = useAtom(activeRoutesAvailableAtom);

  return (
    <React.Fragment>
      {promptType === 'password' && (
        <ExitModal
          visible={promptType === 'password'}
          title={activeRoutes ? 'Are you sure you want to logout?' : 'Logout of Solstice Routing'}
          passwordMessage='Enter your password to continue.'
          generalMessage={
            activeRoutes
              ? 'Logging out will remove active routes to displays within this space.'
              : undefined
          }
          submit='Logout'
          onClose={onClose}
          onSubmit={() => {
            onSubmit();
            setActiveRoutes(false);
          }}
        />
      )}
      {promptType === 'simple' && (
        <BasicPromptModal
          visible={promptType === 'simple'}
          title={activeRoutes ? 'Are you sure you want to logout?' : 'Logout of Solstice Routing'}
          message='Logging out will remove active routes to displays within this space.'
          submit='Logout'
          onClose={onClose}
          onSubmit={() => {
            onSubmit();
            setActiveRoutes(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default LogoutModal;
