import AsyncStorage from '@react-native-async-storage/async-storage';
import { atom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import type { AsyncStorage as IAsyncStorage } from 'jotai/utils/atomWithStorage';
import { Platform } from 'react-native';

// Use this storage object for your atoms
const storage = Platform.select({
  web: undefined,
  default: { ...createJSONStorage(() => AsyncStorage), delayInit: true },
});

const debugArCallsAtom = atomWithStorage('debug_ArCalls', false, storage as IAsyncStorage<boolean>);
const recentlyUsedSpacesAtom = atomWithStorage(
  'recentlyUsedSpaces',
  [],
  storage as IAsyncStorage<string[]>
);

// TODO: Update the default value after Product Owner has a chance to test and provide feedback
// also look into storing this atom
const requireSpaceExitPasswordAtom = atom(!__DEV__);
const requireLogoutPasswordAtom = atom(!__DEV__);

export {
  recentlyUsedSpacesAtom,
  debugArCallsAtom,
  requireLogoutPasswordAtom,
  requireSpaceExitPasswordAtom,
};
