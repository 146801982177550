import React from 'react';
import { Text, View } from 'react-native';

import Tooltip from './Tooltip';

interface Props {
  children?: React.ReactNode;
}

const DisplayAudioTooltip: React.FC<Props> = ({ children }) => {
  return (
    <Tooltip
      testID='display-audio-tooltip'
      accessibilityLabel='Display Audio Tooltip'
      content={
        <View className='w-32'>
          <Text numberOfLines={3} className='font-[Lato-Regular] text-sm leading-5'>
            This display can be used as an audio routing output.
          </Text>
        </View>
      }>
      {children}
    </Tooltip>
  );
};

export default DisplayAudioTooltip;
