import React from 'react';
import { Pressable, Text, View } from 'react-native';

import Menu from './Menu';

import CirclePlus from '../../assets/icons/circle_plus.svg';
import { CustomPreset } from '../models/customPreset';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  visible: boolean;
  presets?: CustomPreset[];
  onOpen?: () => void;
  onClose?: () => void;
  onPreset?: (id: string) => void;
  onSave?: () => void;
}

const SpacePresetsMenu: React.FC<Props> = ({
  children,
  visible,
  presets,
  onOpen,
  onClose,
  onPreset,
  onSave,
}) => {
  const isFullOnPresets = presets && presets.length >= 10;
  return (
    <Menu
      testID='space-presets-menu-button'
      accessibilityLabel='Space Presets Menu'
      visible={visible}
      onOpen={onOpen}
      onClose={onClose}
      bottom={6}
      content={
        <View>
          <Pressable
            className='flex-row px-4 py-3 border-b-[0.5px] border-b-[#000000]/20'
            disabled={isFullOnPresets}
            testID='pressable-preset-save'
            onPress={() => {
              onSave && onSave();
            }}>
            <View className='justify-center'>
              <CirclePlus
                width={18}
                height={18}
                viewBox='0 0 18 18'
                fill={isFullOnPresets ? `#B8BDBF` : `black`}
              />
            </View>
            <Text
              className={`font-[Lato-Bold] text-base ml-[9px] ${
                isFullOnPresets && 'text-[#B8BDBF]'
              }`}>
              Save Custom Preset
            </Text>
          </Pressable>
          {presets?.map((preset) => {
            return (
              <Pressable
                className='px-4 py-3 border-b-[0.5px] border-b-[#000000]/20'
                testID='logout-pressable'
                key={preset.id}
                onPress={() => onPreset && onPreset(preset.id)}>
                <Text className='font-[Lato-Bold] text-base'>{preset.label}</Text>
              </Pressable>
            );
          })}
        </View>
      }>
      {children}
    </Menu>
  );
};

export default SpacePresetsMenu;
