import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from './types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/'), 'https://solstice-routing.mersive.xyz'],
  config: {
    screens: {
      Home: '',
      Space: 'space/:id',
      Help: 'help',
      About: 'about',
      Login: 'login',
      NotRecommended: 'notrecommended',
    },
  },
};

export default linking;
