import React, { useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import PasswordInput from './PasswordInput';

interface Props {
  email: string;
  ssoEnabled: boolean;
  onBack?: () => void;
  onForgotPassword?: () => void;
  onSubmit?: (password: string, sso: boolean) => void;
}

const LoginPasswordForm: React.FC<Props> = ({
  email,
  ssoEnabled,
  onBack,
  onForgotPassword,
  onSubmit,
}) => {
  const [enteredPassword, setEnteredPassword] = useState('');

  return (
    <View className='w-full space-y-3'>
      <View className='h-6 items-start justify-center'>
        <TouchableOpacity testID='login-back-button' onPress={() => onBack?.()}>
          <Text className='font-[Lato-Bold] text-sm text-white'>{'< Back'}</Text>
        </TouchableOpacity>
      </View>
      <Text className='font-[Lato-Bold] text-xs text-white'>Enter password for {email}</Text>
      <PasswordInput
        className='rounded-[5px] h-9 mb-4'
        placeholder='Password'
        placeholderTextColor='#8f9fa5'
        value={enteredPassword}
        onChangeText={setEnteredPassword}
        onSubmitEditing={() => onSubmit?.(enteredPassword, false)}
      />
      <TouchableOpacity
        testID='login-with-password-button'
        disabled={enteredPassword.length === 0}
        onPress={() => onSubmit?.(enteredPassword, false)}
        className={`h-9 rounded-[4px] justify-center ${
          enteredPassword.length === 0 ? 'bg-[#e0b4b4]' : 'bg-[#ff3367]'
        }`}>
        <Text className='font-[Lato-Bold] text-center text-white'>Login with password</Text>
      </TouchableOpacity>
      {ssoEnabled ? (
        <TouchableOpacity
          testID='login-with-sso-button'
          onPress={() => onSubmit?.(enteredPassword, true)}
          className='h-9 bg-white rounded-[4px] justify-center border border-[#FF3367]'>
          <Text className='font-[Lato-Bold] text-center text-[#FF3367]'>Login with SSO</Text>
        </TouchableOpacity>
      ) : null}
      <View className='h-16 items-center justify-center'>
        <TouchableOpacity testID='forgot-password-button' onPress={() => onForgotPassword?.()}>
          <Text className='font-[Lato-Bold] text-sm text-white'>Forgot password?</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LoginPasswordForm;
