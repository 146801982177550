import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { URL } from 'react-native-url-polyfill';

import authAtom from '../store/authAtom';

const API_URL = 'https://kepler-auth-svc.mersive.com/v1';
const API_TOKEN_URL = `${API_URL}/auth/token`;
const API_PASSWORD_URL = `${API_URL}/auth/password`;

const SSO_URL = 'https://kepler-sso.mersive.com';
const SSO_TOKEN_URL = `${SSO_URL}/auth/token`;
const SSO_STATUS_URL = `${SSO_URL}/auth/status`;

WebBrowser.maybeCompleteAuthSession();

const redirectUri = AuthSession.makeRedirectUri({
  path: 'login',
  useProxy: false,
});

export type AuthSsoStatus = {
  org: string;
  ssoEnabled: boolean;
  ssoOnly: boolean;
};

export type AuthErrorType =
  | 'credentials' // Invalid credentials
  | 'network' // Network error
  | 'unknown'; // Unknown error

export class AuthError extends Error {
  type: string;

  constructor(type: AuthErrorType) {
    switch (type) {
      case 'credentials':
        super('Invalid credentials');
        break;
      case 'network':
        super('Network error');
        break;
      default:
        super('Unknown error');
    }
    this.name = 'AuthError';
    this.type = type;
  }
}

const useAuthentication = () => {
  const setAuth = useSetAtom(authAtom);

  const fetchToken = async (url: string, method: string, body: any) => {
    try {
      const res = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });
      if (res.status === 403) {
        throw new AuthError('credentials');
      }
      if (res.status !== 200) {
        throw new AuthError('unknown');
      }
      const resData = await res.json();
      return resData.access_token;
    } catch (err: any) {
      if (err instanceof AuthError) {
        throw err;
      }
      throw new AuthError('network');
    }
  };

  // Submit e-mail and obtain SSO status
  const submitEmail = useCallback(
    async (email: string, remember: boolean): Promise<AuthSsoStatus> => {
      try {
        const res = await fetch(`${SSO_STATUS_URL}/${email}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (res.status !== 200) {
          throw new AuthError('unknown');
        }
        const data = (await res.json()) as AuthSsoStatus;
        setAuth((prev) => ({ ...prev, ...data, username: email, rememberMe: remember }));
        return data;
      } catch (err) {
        if (err instanceof AuthError) {
          throw err;
        }
        throw new AuthError('network');
      }
    },
    [setAuth]
  );

  const login = useCallback(
    async (email: string, password: string) => {
      console.log('Login - user:', email);
      const token = await fetchToken(API_TOKEN_URL, 'POST', JSON.stringify({ email, password }));
      setAuth((prev) => ({ ...prev, isAuthenticated: true, username: email, token }));
    },
    [setAuth]
  );

  const loginWithSSO = useCallback(
    async (email: string) => {
      console.log('SSO login - user:', email);
      try {
        const res = await fetch(
          `${SSO_TOKEN_URL}?${new URLSearchParams({
            RelayState: redirectUri,
          })}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password: null }),
          }
        );
        if (!res.ok) {
          throw new AuthError('network');
        }

        const resData = await res.json();
        const { redirectURL } = resData;

        // The second param is needed for this to work on Android
        // https://github.com/expo/expo/issues/6289#issuecomment-847893448
        const authResult = await WebBrowser.openAuthSessionAsync(redirectURL, redirectUri);
        if (authResult.type !== 'success') {
          return;
        }

        const url = new URL(authResult.url);
        const ssoToken = url.searchParams.get('token');
        if (!ssoToken) {
          throw new AuthError('unknown');
        }

        // Obtain access_token from SSO token
        const token = await fetchToken(SSO_TOKEN_URL, 'PUT', JSON.stringify({ token: ssoToken }));
        setAuth((prev) => ({ ...prev, isAuthenticated: true, username: email, token }));
      } catch (err: any) {
        if (err instanceof AuthError) {
          throw err;
        } else {
          throw new AuthError('unknown');
        }
      }
    },
    [setAuth]
  );

  const logout = useCallback(() => {
    setAuth((prev) => ({
      isAuthenticated: false,
      rememberMe: prev.rememberMe,
      username: prev.rememberMe ? prev.username : '',
    }));
  }, [setAuth]);

  const resetPassword = useCallback(async (email: string) => {
    try {
      const res = await fetch(API_PASSWORD_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (res.status !== 200) {
        throw new AuthError('unknown');
      }
    } catch (err: any) {
      if (err instanceof AuthError) {
        throw err;
      }
      throw new AuthError('network');
    }
  }, []);

  return { submitEmail, login, loginWithSSO, logout, resetPassword };
};

export default useAuthentication;
