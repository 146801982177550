import React from 'react';
import { Pressable, StyleProp, View, ViewStyle } from 'react-native';

import Arrows from '../../assets/icons/up-down-arrows.svg';

interface Props {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  direction: 'up' | 'down' | 'none';
  onPressed: () => void;
}

const SortButton: React.FC<Props> = ({ children, style, direction, onPressed }) => {
  const offset = direction === 'none' ? 0 : direction === 'up' ? -6 : 6;

  const arrowStyle = (arrowDir: string) => {
    return {
      flexShrink: 0,
      marginTop: arrowDir === 'none' ? 0 : arrowDir === 'up' ? -3 : 3,
    };
  };

  return (
    <View style={style}>
      <Pressable
        testID='sort-button'
        className='flex-row items-center space-x-3'
        onPress={onPressed}>
        {children}
        <View style={{ aspectRatio: 9 / 12 }} className='w-2 sm:w-[9px]'>
          <Arrows
            width='100%'
            height='100%'
            viewBox={`0 ${offset} 9 12`}
            color='#ff3367'
            style={arrowStyle(direction)}
          />
        </View>
      </Pressable>
    </View>
  );
};

export default SortButton;
