import { registerRootComponent } from 'expo';
import { registerGlobals } from 'react-native-webrtc-web-shim';

import App from './App';
import { initializeApp } from './src/utils/init';

initializeApp();
registerGlobals();

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
