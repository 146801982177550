import axios from 'axios';

import https from 'https';

import { LookinError } from './lookin.types';

export const needsScreenKey = async (address: string) => {
  const uri = `https://${address}/lookin/info`;

  try {
    const res = await axios.get(uri, {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
      timeout: 1000,
    });

    if (res.status === 200) {
      const data = res.data;
      return data.screenKeyValidationRequired || false;
    }

    throw new LookinError('unknown');
  } catch (err) {
    if (err instanceof LookinError) {
      throw err;
    }

    throw new LookinError('network');
  }
};

export const getScreenPreview = async (address: string, screenKey?: string) => {
  const uri = `https://${address}/lookin/thedisplay.jpg${
    screenKey ? '?screenKey=' + screenKey : ''
  }`;

  if (screenKey && screenKey.length !== 4) {
    throw new LookinError('screenKey');
  }

  try {
    const res = await axios.get(uri, {
      responseType: 'arraybuffer',
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
      timeout: 10000,
    });

    if (res.status === 200) {
      let headers = res.headers;
      if (headers['content-type'] === 'image/jpeg') {
        const base64 = Buffer.from(res.data, 'binary').toString('base64');
        return `data:image/jpeg;base64,${base64}`;
      }
      throw new LookinError('screenKey');
    }
    throw new LookinError('unknown');
  } catch (err) {
    if (err instanceof LookinError) {
      throw err;
    }
    throw new LookinError('network');
  }
};
