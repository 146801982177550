import * as ScreenOrientation from 'expo-screen-orientation';
import { Provider as JotaiProvider } from 'jotai';
import React, { useEffect } from 'react';
import { StatusBar } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from './src/hooks/useCachedResources';
import Navigation from './src/navigation';

import './src/styles';

const App = () => {
  const isLoadingComplete = useCachedResources();

  useEffect(() => {
    const lockScreenOrientation = async () => {
      try {
        await ScreenOrientation.unlockAsync();
      } catch {
        // Suppress errors
      }
    };
    lockScreenOrientation();
  }, []);

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <SafeAreaProvider>
      <StatusBar barStyle='light-content' />
      <JotaiProvider>
        <Navigation />
      </JotaiProvider>
    </SafeAreaProvider>
  );
};

export default App;
