import React, { useCallback, useEffect, useState } from 'react';
import {
  MediaStream,
  RTCPeerConnection,
  RTCSessionDescription,
} from 'react-native-webrtc-web-shim';

import ScreenCapturePickerView from './ScreenCapturePickerView';
import ScreenKeyModal from './ScreenKeyModal';

import useScreenCapture from '../hooks/useScreenCapture';
import useToast from '../hooks/useToast';
import { DisplayModel } from '../models/display';
import { needsScreenKey, terminateRTC } from '../utils/webrtc';

type MirrorState = {
  status:
    | 'unknown' // initial state
    | 'screenKey' // asking for screen key
    | 'connecting' // trying to connect
    | 'connected' // connected (need to send stream)
    | 'error' // error connecting
    | 'success'; // connected and streaming
  screenKey?: string;
  toastId?: string;
  error?: string;
  connection?: RTCPeerConnection;
  ws?: WebSocket;
  stream?: MediaStream;
};

interface Props {
  display: DisplayModel;
  onClose: () => void;
}

const ScreenMirror: React.FC<Props> = ({ display, onClose }) => {
  const [mirrorState, setMirrorState] = useState<MirrorState>({
    status: 'unknown',
  } as MirrorState);

  const capture = useScreenCapture();

  const toast = useToast();

  const { status, toastId, screenKey, error } = mirrorState;
  const { ip, label } = display;

  console.log('ScreenMirror: status=[%s] error=[%s]', status, error);

  useEffect(() => {
    const checkScreenKey = async () => {
      try {
        const needsKey = await needsScreenKey(ip);
        if (needsKey) {
          setMirrorState({
            status: 'screenKey',
          });
        } else {
          setMirrorState({
            status: 'connecting',
          });
        }
      } catch (_err: any) {
        setMirrorState({
          status: 'error',
          error: 'Network error. Please check your connection and try again.',
        });
      }
    };

    checkScreenKey();
  }, [ip]);

  useEffect(() => {
    const runEffect = async () => {
      switch (status) {
        case 'error':
          toast.show(error || 'Uknown error. Please try again!', {
            timeout: 5000,
            type: 'error',
            dismiss: 'Dismiss',
          });
          onClose();
          break;

        case 'success':
          if (!toastId) {
            const id = toast.show(`Your screen is being mirrored to ${label}.`, {
              type: 'info',
              dismiss: 'Stop mirroring',
              onDismiss: () => {
                capture.stopCapture();
                terminateRTC(mirrorState.connection, mirrorState.ws, mirrorState.stream);
                onClose();
              },
            });
            setMirrorState((current) => ({ ...current, toastId: id }));
          }
          break;

        case 'connecting':
          const connection = new RTCPeerConnection({
            iceServers: [
              {
                urls: 'stun:stun.services.mozilla.com',
              },
              {
                urls: 'stun:stun.l.google.com:19302',
              },
            ],
          });

          const ws = new WebSocket(
            `ws://${ip}:6443/webrtc?display_name=guest&screen_key=${screenKey}`
          );

          connection.addEventListener('connectionstatechange', (_e) => {
            switch (connection.connectionState) {
              case 'closed':
              case 'failed':
              case 'disconnected':
                terminateRTC(connection, ws, mirrorState.stream);
                break;
            }
          });

          connection.addEventListener('icecandidate', (e) => {
            console.log('icecandidate:', e);
            // @ts-ignore
            if (!e.candidate) {
              return;
            }
            // @ts-ignore
            ws.send(JSON.stringify(e.candidate));
          });

          connection.addEventListener('icecandidateerror', (e) => {
            console.log('icecandidateerror:', e);
          });

          connection.addEventListener('iceconnectionstatechange', (e) => {
            console.log('iceconnectionstatechange:', e);
          });

          connection.addEventListener('signalingstatechange', (e) => {
            console.log('signalingstatechange:', e);
          });

          ws.onerror = (e) => {
            console.log('ws error:', e);
            setMirrorState({
              status: 'error',
              error: 'Failed to connect to the display.',
            });
          };

          ws.onopen = () => {
            console.log('ws open');
          };

          ws.onclose = (e) => {
            console.log('ws close:', e);
            terminateRTC(connection, ws);
            toastId && toast.hide(toastId);
            // onClose();
          };

          ws.onmessage = (e) => {
            console.log('ws message:', JSON.parse(e.data));
            let message;

            try {
              message = JSON.parse(e.data);
            } catch (err) {
              console.error(err);
            }

            if (message.auth === 'invalid screen key') {
              terminateRTC(connection, ws);
              setMirrorState({
                status: 'screenKey',
                error: 'Invalid screen key.',
              });
            } else if (message.auth === 'valid screen key') {
              setMirrorState({
                status: 'connected',
                connection,
                ws,
              });
            } else if (message.type === 'answer') {
              connection.setRemoteDescription(new RTCSessionDescription(message));
            } else if (message.candidate) {
              connection.addIceCandidate(message).catch((err) => {
                console.error(err);
              });
            }
          };
          break;

        case 'connected':
          if (capture.error) {
            terminateRTC(mirrorState.connection, mirrorState.ws, capture.stream);
            setMirrorState({
              status: 'error',
              error: 'Failed to start screen capture.',
            });
            return;
          }

          if (!capture.stream) {
            try {
              await capture.startCapture();
            } catch (err) {
              terminateRTC(mirrorState.connection, mirrorState.ws);
              setMirrorState({
                status: 'error',
                error: 'Failed to start screen capture.',
              });
              return;
            }
          } else {
            const { stream } = capture;

            stream.getVideoTracks()[0].onended = () => {
              terminateRTC(connection, ws, stream);
            };

            for (const track of stream.getTracks()) {
              console.log('adding track:', track);
              mirrorState.connection?.addTrack(track, stream);
            }

            mirrorState.connection
              ?.createOffer({
                mandatory: {
                  OfferToReceiveAudio: true,
                  OfferToReceiveVideo: true,
                  VoiceActivityDetection: false,
                },
              })
              .then((offer) => {
                mirrorState.connection?.setLocalDescription(offer as RTCSessionDescription);
                mirrorState.ws?.send(JSON.stringify(offer));
              })
              .catch((err) => {
                console.error(err);
              });

            setMirrorState((curr) => ({ ...curr, stream, status: 'success' }));
          }

          break;
      }
    };

    runEffect();
  }, [capture, error, ip, label, mirrorState, onClose, screenKey, status, toast, toastId]);

  const handleScreenKey = useCallback((key: string) => {
    setMirrorState({
      status: 'connecting',
      screenKey: key,
    });
  }, []);

  if (status === 'screenKey' || status === 'connecting') {
    const isInvalid = !!mirrorState.error;
    const isConnecting = status === 'connecting';
    const message = isInvalid
      ? 'Incorrect screen key, please try again.'
      : 'Enter the screen key on the display.';

    return (
      <ScreenKeyModal
        visible
        autoFocus
        loading={isConnecting}
        title={display.label}
        message={isConnecting ? 'Connecting...' : message}
        error={isInvalid}
        onClose={onClose}
        onSubmit={handleScreenKey}
      />
    );
  }

  if (capture.ref) {
    // @ts-ignore
    return <ScreenCapturePickerView ref={capture.ref} />;
  }

  return null;
};

export default ScreenMirror;
