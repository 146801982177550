import { useAtomValue } from 'jotai';
import React from 'react';
import { Platform, Pressable, Text, View } from 'react-native';

import Menu from './Menu';

import LogoutIcon from '../../assets/icons/exit.svg';
import AboutIcon from '../../assets/icons/info-circle.svg';
import HelpIcon from '../../assets/icons/question-mark-circle.svg';
import SolsticeIcon from '../../assets/icons/Solstice.svg';
import { usernameAtom } from '../store/authAtom';
import { openSolsticeClient } from '../utils/linking';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  visible: boolean;
  headerHeight?: number;
  onOpen?: () => void;
  onClose?: () => void;
  onHelp?: () => void;
  onAbout?: () => void;
  onLogout?: () => void;
}

const UserMenu: React.FC<Props> = ({
  children,
  visible,
  headerHeight,
  onOpen,
  onClose,
  onHelp,
  onAbout,
  onLogout,
}) => {
  const username = useAtomValue(usernameAtom);

  return (
    <Menu
      testID='user-menu-button'
      accessibilityLabel='User Menu'
      className='rounded-t-none rounded-b-[10px]'
      style={
        // iOS doesn't seem to include the StatusBar height in the header thus using a hardcoded value to compensate/offset
        headerHeight ? { top: headerHeight + Platform.select({ ios: 20, default: 0 }) } : {}
      }
      visible={visible}
      onOpen={onOpen}
      onClose={onClose}
      // top and right are needed to avoid errors, but not used
      top={0}
      right={0}
      content={
        <View>
          <View className='flex-row justify-end p-3 border-b-2 border-b-[#FF3367]'>
            <Text
              testID='username-text'
              accessibilityRole='text'
              accessibilityLabel='Username'
              className='ml-2 font-[Lato-Bold] text-lg'>
              {username}
            </Text>
          </View>
          <Pressable
            className='flex-row p-4 border-b-[0.5px] border-b-[#21232F]'
            onPress={() => {
              openSolsticeClient();
              onClose && onClose();
            }}>
            <View className='w-5 items-center justify-center'>
              <SolsticeIcon width={14} height={21} viewBox='0 0 14 21' color='black' />
            </View>
            <Text className='ml-[19px] text-lg text-[#20293C] font-[Lato-Regular]'>
              Open Solstice app
            </Text>
          </Pressable>
          <View className='p-4 border-b-[0.5px] border-b-[#21232F]'>
            <Pressable
              className='flex-row'
              testID='about-button'
              onPress={() => onAbout && onAbout()}>
              <View className='justify-center'>
                <AboutIcon viewBox='0 0 20 20' color='#20293C' />
              </View>
              <Text className='ml-[19px] text-lg text-[#20293C] font-[Lato-Regular]'>About</Text>
            </Pressable>
          </View>
          <View className='p-4 border-b-[0.5px] border-b-[#21232F]'>
            <Pressable className='flex-row' testID='help-button' onPress={() => onHelp && onHelp()}>
              <View className='justify-center'>
                <HelpIcon viewBox='0 0 20 20' color='#20293C' />
              </View>
              <Text className='ml-[19px] text-lg text-[#20293C] font-[Lato-Regular]'>Help</Text>
            </Pressable>
          </View>
          <View className='flex-row p-4 border-b-[0.5px] border-b-[#21232F]'>
            <Pressable
              className='flex-row'
              testID='logout-button'
              onPress={() => onLogout && onLogout()}>
              <View className='justify-center'>
                <LogoutIcon viewBox='0 0 20 20' color='#20293C' />
              </View>
              <Text className='ml-[19px] text-lg text-[#20293C] font-[Lato-Regular]'>Logout</Text>
            </Pressable>
          </View>
        </View>
      }>
      {children}
    </Menu>
  );
};

export default UserMenu;
