import { useAtom } from 'jotai';
import React from 'react';
import { Text, View } from 'react-native';

import Switch from './Switch';

import DisplayIcon from '../../assets/icons/display.svg';
import authAtom from '../store/authAtom';
import {
  debugArCallsAtom,
  requireLogoutPasswordAtom,
  requireSpaceExitPasswordAtom,
} from '../store/settingsAtoms';

const DebugSettings: React.FC = () => {
  const [debugMakeArCalls, setDebugMakeArCalls] = useAtom(debugArCallsAtom);
  const [requireSpaceExitPassword, setRequirePasswordOnExit] = useAtom(
    requireSpaceExitPasswordAtom
  );
  const [requireLogoutPassword, setRequireLogoutPassword] = useAtom(requireLogoutPasswordAtom);
  const [auth, setAuth] = useAtom(authAtom);

  return (
    <View className='flex-row p-3'>
      <View className='w-5 h-4 mr-5 mt-1'>
        <DisplayIcon width={24} height={22} viewBox='0 0 24 22' color='black' />
      </View>
      <View className='flex-1 space-y-3'>
        <Text className='text-[18px] font-[Lato-Regular]'>Debug</Text>
        <View className='flex-row items-center justify-between'>
          <Text className='mr-2 text-[16px] font-[Lato-Regular]'>Make AR Calls:</Text>
          <Switch
            onValueChange={() => {
              setDebugMakeArCalls((prev) => !prev);
            }}
            value={debugMakeArCalls}
          />
        </View>
        <View className='flex-row items-center justify-between'>
          <Text className='mr-2 text-[16px] font-[Lato-Regular]'>Require Exit Password:</Text>
          <Switch
            onValueChange={() => {
              setRequirePasswordOnExit((prev) => !prev);
            }}
            value={requireSpaceExitPassword}
          />
        </View>
        <View className='flex-row items-center justify-between'>
          <Text className='mr-2 text-[16px] font-[Lato-Regular]'>Require Logout Password:</Text>
          <Switch
            onValueChange={() => {
              setRequireLogoutPassword((prev) => !prev);
            }}
            value={requireLogoutPassword}
          />
        </View>
        <View className='flex-row items-center justify-between'>
          <Text className='mr-2 text-[16px] font-[Lato-Regular]'>SSO Enabled:</Text>
          <Switch
            onValueChange={() => {
              setAuth((prev) => ({ ...prev, ssoEnabled: !prev.ssoEnabled }));
            }}
            value={!!auth.ssoEnabled}
          />
        </View>
      </View>
    </View>
  );
};

export default DebugSettings;
