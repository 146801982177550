import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';

interface props {
  children?: React.ReactNode;
}

const Screen: React.FC<props> = ({ children }) => {
  return (
    <LinearGradient className='flex-1' colors={['#455C86', '#31394E']}>
      {children}
    </LinearGradient>
  );
};

export default Screen;
