import React from 'react';
import { Text, View } from 'react-native';

import Tooltip from './Tooltip';

interface Props {
  children?: React.ReactNode;
}

const DisplaySubscriptionToolTip: React.FC<Props> = ({ children }) => {
  return (
    <Tooltip
      testID='display-subscription-tooltip'
      accessibilityLabel='Display Subscription Tooltip'
      drawPointer={false}
      content={
        <View className='w-44 space-y-5'>
          <Text className='font-[Lato-Bold] text-sm leading-5'>Subscription Expired</Text>
          <Text className='font-[Lato-Regular] text-sm leading-5'>
            This display’s Solstice Routing subscription expired. Contact your IT admin to renew.
          </Text>
        </View>
      }>
      {children}
    </Tooltip>
  );
};

export default DisplaySubscriptionToolTip;
