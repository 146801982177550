import React, { useCallback } from 'react';
import { Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';

import Check from '../../assets/icons/check.svg';

interface Props {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
}

const Checkbox: React.FC<Props> = ({ label, checked, onChange, style }) => {
  const handlePress = useCallback(() => {
    onChange(!checked);
  }, [checked, onChange]);

  return (
    <View style={style} className='flex-row'>
      <Pressable
        testID='remember-email-checkbox'
        accessibilityLabel='Checkbox'
        className='h-4 w-4 rounded-sm bg-white justify-center items-center border border-[#D4D4D5]'
        onPress={handlePress}>
        {checked && <Check height='100%' width='100%' viewBox='0 0 12 9' color='black' />}
      </Pressable>
      <Text className='text-[#F2F2F2] font-[Lato-Regular] ml-[10px]'>{label}</Text>
    </View>
  );
};

export default Checkbox;
