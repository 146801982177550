import useSpaces from './useSpaces';

import { Space } from '../models/space';

type SpaceData = {
  data: Space | undefined;
  status: 'success' | 'error' | 'loading';
  error: string;
};

const useSpace = (id: string): SpaceData => {
  const { data: spaces, status: spacesStatus, error: spacesError } = useSpaces();

  if (spacesStatus !== 'success') {
    return { data: undefined, error: spacesError, status: spacesStatus };
  }

  const space = spaces.find((s) => s.id === id);
  if (!space) {
    return {
      data: undefined,
      status: 'error',
      error: `Could not find the space with id=[${id}] from [${spaces}]`,
    };
  }

  return { data: space, status: 'success', error: '' };
};

export default useSpace;
