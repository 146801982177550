import axios from 'axios';
import { MediaStream, RTCPeerConnection } from 'react-native-webrtc-web-shim';

import { WebrtcError } from './webrtc.types';

export const needsScreenKey = async (ip: string) => {
  const uri = `http://${ip}:6443/ping`;

  try {
    const res = await axios.get(uri, {
      timeout: 1000,
    });

    if (res.status === 200) {
      return res.data !== 'ScreenKeyDisabled';
    }

    throw new WebrtcError('unknown');
  } catch (err) {
    if (err instanceof WebrtcError) {
      throw err;
    }

    throw new WebrtcError('network');
  }
};

export const terminateRTC = (
  connection: RTCPeerConnection | undefined = undefined,
  ws: WebSocket | undefined = undefined,
  stream: MediaStream | undefined = undefined
) => {
  if (connection) {
    connection.close();
  }

  if (ws) {
    ws.close();
  }

  if (stream) {
    stream.getTracks().forEach((track) => track.stop());
  }
};
