import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

interface Props {
  children?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
}

const Form: React.FC<Props> = ({ children }) => {
  return <form>{children}</form>;
};

export default Form;
