import * as ar from '@mersive/active-routing';
import { useAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Connection } from '../models/connection';
import { DisplayModel } from '../models/display';
import { availableSpacesAtom } from '../store/spacesAtom';

const SHOULD_SKIP_CALLS = __DEV__;

const useActiveRouting = (spaceId: string) => {
  const [spaces] = useAtom(availableSpacesAtom);
  const space = spaces.find((s) => s.id === spaceId);

  const [connections, setConnections] = useState<Connection[]>([]);
  const [displays] = useState<DisplayModel[]>(space ? space.displays : []);

  const refresh = useCallback(async () => {
    if (SHOULD_SKIP_CALLS) return;

    let updatedConnections: Connection[] = [];

    for (const dev of displays) {
      try {
        const session = await ar.getSession(dev.ip);
        if (session.state !== 'source') {
          continue;
        }
        const arConns = await ar.enumerate(dev.ip);
        for (const arConn of arConns) {
          const toDev = displays.find((d) => d.ip === arConn.ip);
          if (!toDev) {
            continue;
          }
          const conn: Connection = {
            id: arConn.guid,
            from: dev,
            to: toDev,
          };

          updatedConnections.push(conn);
        }
      } catch (err) {
        throw err;
      }
    }

    setConnections(updatedConnections);
  }, [displays]);

  const connect = useCallback(
    async (from: DisplayModel, to: DisplayModel) => {
      if (!displays.find((d) => d.id === from.id) || !displays.find((d) => d.id === to.id)) {
        throw new Error('cannot connect from/to unknown display');
      }

      let guid: string = '';
      if (SHOULD_SKIP_CALLS) {
        guid = uuidv4();
      } else {
        guid = await ar.connect(from.ip, to.ip, {
          post: 'fullscreen',
          resolution: '1920x1080',
          message: `Sharing from ${from.label}`,
          background: to.color,
          foreground: '#ffffff',
          audio: false,
        });
      }
      const conn: Connection = {
        id: guid,
        from,
        to,
      };
      setConnections((prev) => prev.concat([conn]));
    },
    [displays]
  );

  const disconnect = useCallback(
    async (from: DisplayModel, to: DisplayModel) => {
      if (SHOULD_SKIP_CALLS) return;

      const connectionToRemove = connections.find((r) => r.from === from && r.to === to);
      if (!connectionToRemove) {
        throw new Error(`cannot disconnect unknown connection`);
      }

      const connIndex = connections.findIndex((c) => c.id === connectionToRemove.id);
      if (connIndex < 0) {
      }
      await ar.terminate(connectionToRemove.from.ip, connectionToRemove.id);
      setConnections((prev) => prev.filter((c) => c.id !== connectionToRemove.id));
    },
    [connections]
  );

  const message = useCallback(async (to: DisplayModel, outputMessage: string) => {
    await ar.configure(to.ip, {
      presence: true,
      message: `${outputMessage}`,
      background: to.color,
      foreground: '#ffffff',
    });
  }, []);

  const disconnectAll = useCallback(async (display: DisplayModel) => {
    if (SHOULD_SKIP_CALLS) {
      setConnections([]);
      return;
    }

    await ar.terminateAll(display.ip);
    setConnections([]);
  }, []);

  return {
    connections,
    connect,
    disconnect,
    refresh,
    message,
    disconnectAll,
    displays,
  };
};

export default useActiveRouting;
