import React, { useState } from 'react';
import { Text, View } from 'react-native';

import DebugSettings from './DebugSettings';
import Menu from './Menu';
import Switch from './Switch';
import TextSwitch from './TextSwitch';

import DisconnectUsersIcon from '../../assets/icons/disconnect_users.svg';
import DisplayIcon from '../../assets/icons/display.svg';
import GearSolidIcon from '../../assets/icons/gear_solid.svg';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  visible: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

const SpaceSettingsMenu: React.FC<Props> = ({ children, visible, onOpen, onClose }) => {
  const [showRoutingBanner, setRoutingBanner] = useState(false);
  const [presentation, setPresentation] = useState(true);

  return (
    <Menu
      testID='space-settings-menu'
      accessibilityLabel='Space Settings Menu'
      visible={visible}
      onOpen={onOpen}
      onClose={onClose}
      bottom={5}
      right={0}
      content={
        <View className='flex-1 w-[286px]'>
          <View className='flex-row mb-1 p-3 border-b-2 border-b-[#FF3367] items-center'>
            <GearSolidIcon width={22} height={22} viewBox='0 0 22 22' color='black' />
            <Text className='ml-5 text-lg font-[Lato-Bold]'>Settings</Text>
          </View>
          <View className='flex-row mb-1 p-3 border-b-2 border-b-black items-center'>
            <DisconnectUsersIcon width={22} height={22} viewBox='0 0 22 22' color='black' />
            <Text className='ml-[18px] text-[18px] font-[Lato-Regular]'>Disconnect all users</Text>
          </View>
          <View className='flex-row p-3'>
            <View className='w-5 h-4 mr-5 mt-1'>
              <DisplayIcon width='100%' height='100%' viewBox='0 0 24 22' color='black' />
            </View>
            <View className='flex-1'>
              <Text className='text-[18px] font-[Lato-Regular]'>Display options</Text>
              <Text className='text-[16px] mt-[22px] font-[Lato-Regular]'>
                Moderator sharing mode:
              </Text>
              <TextSwitch
                className='mt-2'
                leftText='Presentation'
                rightText='Comparison'
                leftActive={presentation}
                onToggle={() => {
                  setPresentation((prev) => !prev);
                }}
              />
              <View className='flex-row items-center mt-[30px] justify-between'>
                <Text className='text-[16px] font-[Lato-Regular]'>Show routing banners:</Text>
                <View className='mt-1'>
                  <Switch
                    value={showRoutingBanner}
                    onValueChange={() => {
                      setRoutingBanner((prev) => !prev);
                    }}
                  />
                </View>
              </View>
            </View>
          </View>
          {__DEV__ && <DebugSettings />}
        </View>
      }>
      {children}
    </Menu>
  );
};

export default SpaceSettingsMenu;
