import { useCallback, useState } from 'react';
import { mediaDevices, MediaStream } from 'react-native-webrtc-web-shim';

import { ScreenCaptureData } from './useScreenCapture.types';

import { terminateRTC } from '../utils/webrtc';
import { WebrtcError } from '../utils/webrtc.types';

const useScreenCapture = (): ScreenCaptureData => {
  const [error, setError] = useState<WebrtcError>();
  const [stream, setStream] = useState<MediaStream>();

  const startCapture = useCallback(async () => {
    if (stream) {
      return;
    }

    mediaDevices
      .getDisplayMedia()
      .then((s) => {
        setStream(s);
        setError(undefined);
      })
      .catch((_err) => {
        setError(new WebrtcError('capture'));
      });
  }, [stream]);

  const stopCapture = useCallback(async () => {
    if (!stream) {
      return;
    }

    terminateRTC(undefined, undefined, stream);
    setStream(undefined);
    setError(undefined);
  }, [stream]);

  return {
    error,
    stream,
    startCapture,
    stopCapture,
  };
};

export default useScreenCapture;
