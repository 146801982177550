import { Color } from '@mersive/active-routing';

import { DisplayModel } from '../models/display';

export type Route = {
  source: DisplayModel;
  sink: DisplayModel;
  color: Color;
  isActive: boolean;
};

export const getRouteId = (route: Route) => {
  return `${route.source.row}.${route.source.col}-${route.sink.row}.${route.sink.col}-${route.color}`;
};
