import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  Modal as RNModal,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

export interface ModalProps {
  children?: React.ReactNode;
  visible: boolean;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, visible, onClose }) => {
  return (
    <RNModal animationType='slide' transparent={true} visible={visible} onRequestClose={onClose}>
      <TouchableWithoutFeedback testID='modal-backdrop' onPress={onClose}>
        <View className='flex-1 items-center justify-center select-none'>
          <TouchableWithoutFeedback onPress={() => {}}>
            <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'position' : 'height'}>
              <LinearGradient
                testID='modal-content'
                className='px-[15px] pt-[10px] pb-[22px] rounded-[10px] shadow-md shadow-black/50'
                colors={['#ffffff', '#d4d4d5']}>
                {children}
              </LinearGradient>
            </KeyboardAvoidingView>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </RNModal>
  );
};

export default Modal;
