import React, { useCallback } from 'react';
import { Text, View } from 'react-native';

import LoadingSpinner from './LoadingSpinner';
import Modal, { ModalProps } from './Modal';
import ScreenKeyInput from './ScreenKeyInput';

interface Props extends ModalProps {
  title: string;
  message: string;
  autoFocus?: boolean;
  loading?: boolean;
  error?: boolean;
  onSubmit?: (key: string) => void;
}

const ScreenKeyModal: React.FC<Props> = ({
  visible,
  title,
  message,
  autoFocus,
  loading,
  error,
  onClose,
  onSubmit,
}) => {
  const handleSubmit = useCallback(
    (key: string) => {
      onSubmit && onSubmit(key);
    },
    [onSubmit]
  );

  return (
    <Modal visible={visible} onClose={onClose}>
      <View className='w-96 h-60 items-center'>
        <Text className='font-[Lato-Bold] text-2xl my-2'>{title}</Text>
        <Text className={`font-[Lato-Regular] text-xl mb-6 mt-4 ${error && 'text-[#a1403e]'}`}>
          {message}
        </Text>
        {loading ? (
          <View className='flex-1 items-center justify-center'>
            <LoadingSpinner size={96} />
          </View>
        ) : (
          <ScreenKeyInput count={4} autoFocus={autoFocus} onSubmit={handleSubmit} />
        )}
      </View>
    </Modal>
  );
};

export default ScreenKeyModal;
