import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Dimensions,
  Modal,
  Platform,
  Pressable,
  StatusBar,
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  children?: React.ReactNode;
  testID?: string;
  accessibilityLabel?: string;
  content: React.ReactNode;
  drawPointer?: boolean;
}

const Tooltip: React.FC<Props> = ({
  children,
  testID,
  accessibilityLabel,
  content,
  drawPointer = true,
}) => {
  const containerRef = useRef<View>(null);
  const [visible, setVisible] = useState(false);
  const [layout, setLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    windowWidth: 0,
    windowHeight: 0,
  });

  const updateLayout = (windowWidth: number, windowHeight: number) => {
    containerRef.current?.measureInWindow((x, y, width, height) => {
      setLayout({ x, y, width, height, windowWidth, windowHeight });
    });
  };

  // After switching from native-stack to stack, the first `measure()` call started
  // returning incorrect values. This is a workaround to make sure the layout is
  // updated every time the menu visibility changes.
  useEffect(() => {
    if (layout.windowWidth > 0 && layout.windowHeight > 0) {
      updateLayout(layout.windowWidth, layout.windowHeight);
    }
  }, [visible, layout.windowWidth, layout.windowHeight]);

  useEffect(() => {
    const listener = Dimensions.addEventListener('change', ({ window, screen }) => {
      if (Platform.OS === 'web') {
        updateLayout(window.width, window.height);
      } else {
        updateLayout(screen.width, screen.height);
      }
    });
    return () => {
      listener.remove();
    };
  }, []);

  const handleLayout = useCallback(() => {
    const windowWidth = Platform.select({
      web: Dimensions.get('window').width,
      default: Dimensions.get('screen').width,
    });
    const windowHeight = Platform.select({
      web: Dimensions.get('window').height,
      android: Dimensions.get('window').height - (StatusBar.currentHeight || 0),
      default: Dimensions.get('screen').height,
    });
    updateLayout(windowWidth, windowHeight);
  }, []);

  const getTooltipDirection = useCallback(() => {
    const centerX = layout.x + layout.width / 2;
    const centerY = layout.y + layout.height / 2;
    const left = centerX;
    const right = layout.windowWidth - centerX;
    const top = centerY;
    const bottom = layout.windowHeight - centerY;

    const directionsX = [
      { direction: 'left', distance: left },
      { direction: 'right', distance: right },
    ].sort((a, b) => b.distance - a.distance);

    const directionsY = [
      { direction: 'top', distance: top },
      { direction: 'bottom', distance: bottom },
    ].sort((a, b) => b.distance - a.distance);

    if (directionsX[0].distance > directionsY[0].distance) {
      return [directionsX[0].direction, directionsY[0].direction];
    }
    return [directionsY[0].direction, directionsX[0].direction];
  }, [layout]);

  const getTooltipStyle = useCallback(
    (pointerDirection: string, bubbleDirection: string) => {
      const pointer: StyleProp<ViewStyle> = {
        top: layout.y,
        left: layout.x,
      };
      const bubble: StyleProp<ViewStyle> = {};

      switch (bubbleDirection) {
        case 'top':
          bubble.bottom = layout.windowHeight - layout.y - layout.height / 2 - 20;
          break;
        case 'bottom':
          bubble.top = layout.y + layout.height / 2 - 20;
          break;
        case 'left':
          bubble.right = layout.windowWidth - layout.x - layout.width / 2 - 20;
          break;
        case 'right':
          bubble.left = layout.x + layout.width / 2 - 20;
          break;
      }

      switch (pointerDirection) {
        case 'top':
          pointer.transform = [
            { translateX: -8 + layout.width / 2 },
            { translateY: -15 },
            { rotate: '180deg' },
          ];
          bubble.bottom = layout.windowHeight - layout.y + 15;
          break;
        case 'bottom':
          pointer.transform = [
            { translateX: layout.width / 2 - 7.5 },
            { translateY: layout.height },
          ];
          bubble.top = layout.y + layout.height + 15;
          break;
        case 'left':
          pointer.transform = [
            { translateX: -16 },
            { translateY: -8 + layout.height / 2 },
            { rotate: '90deg' },
          ];
          bubble.right = layout.windowWidth - layout.x + 15;
          break;
        case 'right':
          pointer.transform = [
            { translateX: layout.width },
            { translateY: -8 + layout.height / 2 },
            { rotate: '-90deg' },
          ];
          bubble.left = layout.x + layout.width + 15;
          break;
        default:
          break;
      }

      return {
        pointer,
        bubble,
      };
    },
    [layout]
  );

  const styles = useMemo(() => {
    const [pointerDirection, bubbleDirection] = getTooltipDirection();
    return getTooltipStyle(pointerDirection, bubbleDirection);
  }, [getTooltipDirection, getTooltipStyle]);

  return (
    <View collapsable={false} ref={containerRef} onLayout={handleLayout}>
      <Pressable
        testID={testID}
        accessibilityLabel={accessibilityLabel}
        hitSlop={10}
        className='select-none'
        onPress={() => setVisible(true)}>
        {children}
      </Pressable>
      <Modal transparent visible={visible} animationType='fade'>
        <TouchableWithoutFeedback testID='tooltip-backdrop' onPress={() => setVisible(false)}>
          <View testID='tooltip' className='flex-1 select-none bg-black/10'>
            {drawPointer === true ? (
              <View
                style={styles.pointer}
                className='select-none absolute w-0 h-0 border-l-8 border-r-8 border-b-[15px] border-l-transparent border-r-transparent border-b-[#f0f0f0]'
              />
            ) : undefined}
            <View
              style={styles.bubble}
              className='select-none absolute rounded-lg p-3 bg-[#f0f0f0] shadow-md'>
              {content}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </View>
  );
};

export default Tooltip;
