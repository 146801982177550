import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

import { unpackAsset } from '../utils/assets';

const useCachedResources = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      try {
        SplashScreen.preventAutoHideAsync();

        console.log('Loading fonts...');
        // Load fonts
        await Font.loadAsync({
          // 100
          'Lato-Thin': unpackAsset(require('../../assets/fonts/Lato-Thin.ttf')),
          'Lato-ThinItalic': unpackAsset(require('../../assets/fonts/Lato-ThinItalic.ttf')),
          // 300
          'Lato-Light': unpackAsset(require('../../assets/fonts/Lato-Light.ttf')),
          'Lato-LightItalic': unpackAsset(require('../../assets/fonts/Lato-LightItalic.ttf')),
          // 400
          'Lato-Regular': unpackAsset(require('../../assets/fonts/Lato-Regular.ttf')),
          'Lato-Italic': unpackAsset(require('../../assets/fonts/Lato-Italic.ttf')),
          // 700
          'Lato-Bold': unpackAsset(require('../../assets/fonts/Lato-Bold.ttf')),
          'Lato-BoldItalic': unpackAsset(require('../../assets/fonts/Lato-BoldItalic.ttf')),
          // 900
          'Lato-Black': unpackAsset(require('../../assets/fonts/Lato-Black.ttf')),
          'Lato-BlackItalic': unpackAsset(require('../../assets/fonts/Lato-BlackItalic.ttf')),
        });
        console.log('Fonts loaded!');
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    };

    loadResourcesAndDataAsync();
  }, [setLoadingComplete]);

  return isLoadingComplete;
};

export default useCachedResources;
