import React, { useCallback, useState } from 'react';
import {
  NativeSyntheticEvent,
  StyleProp,
  TextInput,
  TextInputKeyPressEventData,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  inputRef: React.Ref<TextInput>;
  id: number;
  value: string;
  onKeyPress?: (id: number, key: string) => void;
}

const ScreenKeyCell: React.FC<Props> = ({ style, inputRef, id, value, onKeyPress }) => {
  const [focused, setFocused] = useState(false);

  const handleKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      e.preventDefault();
      onKeyPress && onKeyPress(id, e.nativeEvent.key);
    },
    [id, onKeyPress]
  );

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  return (
    <View
      testID='screen-key-cell'
      style={style}
      className={`w-12 h-20 bg-white justify-center items-center rounded-md border-[2px] ${
        focused ? 'border-[#ff3367]' : 'border-[#333333]'
      }`}>
      <TextInput
        ref={inputRef}
        className='w-12 h-20 font-[Lato-Bold] text-4xl text-center caret-transparent outline-none'
        keyboardType='number-pad'
        value={value}
        maxLength={1}
        caretHidden={true}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </View>
  );
};

export default ScreenKeyCell;
