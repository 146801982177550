import Constants from 'expo-constants';
import React from 'react';
import { Text, View } from 'react-native';

import Logo from '../../assets/images/solstice_routing_logo_white.svg';
import Screen from '../components/Screen';
import { RootStackScreenProps } from '../navigation/types';

interface Props extends RootStackScreenProps<'About'> {}

const AboutScreen: React.FC<Props> = () => {
  const currentYear = new Date().getFullYear();
  const version = Constants.expoConfig?.version ?? '0.0.0';

  return (
    <Screen>
      <View className='flex-1 items-center'>
        <View className='h-full max-w-[1060px] px-3'>
          <View className='flex-1 items-center justify-center'>
            <View
              style={{ aspectRatio: 1000 / 264 }}
              className='w-11/12 max-w-lg max-h-36 mb-[52px]'>
              <Logo width='100%' height='100%' viewBox='0 0 1000 264' />
            </View>
            <Text className='text-white text-[20px] sm:text-[26px] font-[Lato-Bold] mb-8'>
              Version {version}
            </Text>
            <Text className='text-white text-[16px] sm:text-[20px] font-[Lato-Regular] mb-2'>
              Copyright © 2013–{currentYear} Mersive Technologies, Inc. All rights reserved.
            </Text>
          </View>
        </View>
      </View>
    </Screen>
  );
};

export default AboutScreen;
