import * as Linking from 'expo-linking';

export const openSolsticeClient = async () => {
  const appURL = 'solsticelaunch://';
  const webURL = 'https://www.mersive.com/download/';

  const openDownloadPage = async () => {
    console.log('Try to open download link:', webURL);
    try {
      await Linking.openURL(webURL);
    } catch (e) {
      console.error(e);
    }
  };

  const openApp = async () => {
    console.log('Try to open Solstice Client app:', appURL);
    try {
      const supported = await Linking.canOpenURL(appURL);
      if (supported) {
        await Linking.openURL(appURL);
      } else {
        openDownloadPage();
      }
    } catch (e) {
      console.error(e);
      openDownloadPage();
    }
  };

  return openApp();
};
