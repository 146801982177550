import React, { useEffect, useState } from 'react';
import { Platform, Text, TextInput, TouchableOpacity, View } from 'react-native';

import Checkbox from './Checkbox';

import { validateEmail } from '../utils/email';

interface Props {
  email?: string;
  onSubmit?: (email: string, remeber: boolean) => void;
}

const LoginEmailForm: React.FC<Props> = ({ email = '', onSubmit }) => {
  const [enteredEmail, setEnteredEmail] = useState(email);
  const [isEmailValid, setIsEmailValid] = useState(validateEmail(email));
  const [shouldRememberEmail, setShouldRememberEmail] = useState(email.length > 0);

  // sync state with props
  useEffect(() => {
    if (email.length > 0) {
      setIsEmailValid(validateEmail(email));
      setEnteredEmail(email);
      setShouldRememberEmail(true);
    }
  }, [email]);

  const handleEmailChange = (text: string) => {
    setIsEmailValid(validateEmail(text.trim()));
    setEnteredEmail(text.trim());
  };

  return (
    <View className='w-full space-y-3'>
      <Text className='font-[Lato-Bold] text-xs text-white'>
        Enter your Solstice Cloud e-mail address to get started
      </Text>
      <TextInput
        testID='email-input'
        accessibilityLabel='Email'
        className='h-9 px-4 font-[Lato-Regular] bg-white rounded-[4px] border border-[#D4D4D5] outline-none'
        placeholder='E-mail address'
        placeholderTextColor='#8F9FA5'
        autoFocus={Platform.OS === 'web'}
        autoComplete='email'
        returnKeyType='next'
        onSubmitEditing={() => {
          if (isEmailValid) {
            onSubmit?.(enteredEmail, shouldRememberEmail);
          }
        }}
        autoCapitalize='none'
        value={enteredEmail}
        onChangeText={handleEmailChange}
      />
      <Checkbox
        label='Remember Username'
        checked={shouldRememberEmail}
        onChange={setShouldRememberEmail}
        className='mb-4'
      />
      <TouchableOpacity
        testID='login-button'
        accessibilityRole='button'
        disabled={!isEmailValid}
        onPress={() => {
          onSubmit?.(enteredEmail, shouldRememberEmail);
        }}
        className={`h-9 rounded-[4px] justify-center ${
          isEmailValid ? 'bg-[#FF3367]' : 'bg-[#e0b4b4]'
        }`}>
        <Text className='font-[Lato-Bold] text-center text-white'>Login</Text>
      </TouchableOpacity>
    </View>
  );
};

export default LoginEmailForm;
