import React, { useCallback, useState } from 'react';
import { Text, TextInput, TouchableOpacity, View } from 'react-native';

import Modal, { ModalProps } from './Modal';

interface Props extends ModalProps {
  //Submit will fire the onClose when submit is pushed to close the modal
  onSubmit?: (message: string) => void;
}

const CustomMessageModal: React.FC<Props> = ({ visible, onClose, onSubmit }) => {
  const [message, setMessage] = useState('');
  const hasText = () => {
    if (message.trim().length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleClose = useCallback(() => {
    setMessage(``);
    onClose && onClose();
  }, [setMessage, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit(message);
    handleClose();
  }, [onSubmit, message, handleClose]);

  return (
    <Modal visible={visible} onClose={handleClose}>
      <View className='w-96'>
        <Text className='mb-[17px] font-[Lato-Bold] text-lg text-center'>
          {'Send a message to all connected displays'}
        </Text>
        <View
          className={`flex-row items-center justify-between rounded-[5px] h-10 border border-[#D4D4D5] bg-white pl-[15px] pr-2 font-[Lato-Regular]`}>
          <TextInput
            className={`w-full outline-none text-sm ${
              message.length ? 'font-[Lato-Bold]' : 'font-[Lato-Regular]'
            }`}
            testID='message-input'
            accessibilityLabel='Enter a message'
            placeholder='Enter a message'
            placeholderTextColor='#A8A8A8'
            textAlignVertical='center'
            onChangeText={setMessage}
            value={message}
            autoCorrect={true}
            autoFocus={true}
            enablesReturnKeyAutomatically
            maxLength={40}
            returnKeyType={'done'}
            onSubmitEditing={() => {
              hasText() && handleSubmit();
            }}
          />
        </View>
        <Text className='ml-[15px] mt-1 font-[Lato-Regular] text-[11px] text-[#20293C]'>{`${
          40 - message.length
        }/40 characters remaining`}</Text>
        <View className='flex-row items-center justify-center w-full pt-[14px]'>
          <TouchableOpacity
            testID='message-cancel-button'
            className='w-1/4 mx-3'
            onPress={handleClose}>
            <View className='border border-[#ff3367] bg-white rounded-md py-1 w-full'>
              <Text className='font-[Lato-Bold] text-base text-[#ff3367] text-center'>Cancel</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            testID='message-send-button'
            className='w-1/4 mx-3'
            disabled={!hasText()}
            onPress={handleSubmit}>
            <View
              className={`${
                hasText()
                  ? 'bg-[#ff3367] border border-[#ff3367]'
                  : 'bg-[#e0b4b4] border border-[#e0b4b4]'
              } rounded-md py-1`}>
              <Text className='font-[Lato-Bold] text-base text-white text-center'>Send</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default CustomMessageModal;
