import React from 'react';
import { Pressable, Text, View } from 'react-native';

import Menu from './Menu';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
  visible: boolean;
  onOpen: () => void;
  onClose: () => void;
  onEyesUpFront: () => void;
  onCustomMessage: () => void;
}

const SendMessageMenu: React.FC<Props> = ({
  children,
  visible,
  onOpen,
  onClose,
  onEyesUpFront,
  onCustomMessage,
}) => {
  return (
    <Menu
      testID='send-message-menu-button'
      accessibilityLabel='Send Message Menu'
      visible={visible}
      onOpen={onOpen}
      onClose={onClose}
      bottom={6}
      content={
        <View>
          <Pressable
            className='px-4 py-3 border-b-[0.5px] border-b-[#000000]/20'
            onPress={onEyesUpFront}>
            <Text className='font-[Lato-Bold] text-base'>Eyes up front</Text>
          </Pressable>
          <Pressable className='px-4 py-3' onPress={onCustomMessage}>
            <Text className='font-[Lato-Bold] text-base'>Custom message</Text>
          </Pressable>
        </View>
      }>
      {children}
    </Menu>
  );
};

export default SendMessageMenu;
