import React, { useCallback, useState } from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import Modal, { ModalProps } from './Modal';
import PasswordInput from './PasswordInput';

interface Props extends ModalProps {
  title: string;
  passwordMessage: string;
  generalMessage?: string;
  error?: boolean;
  submit: string;
  onSubmit?: (password: string) => void;
}

const ExitModal: React.FC<Props> = ({
  visible,
  title,
  passwordMessage,
  generalMessage,
  error = false,
  submit,
  onSubmit,
  onClose,
}) => {
  const [enteredPassword, setEnteredPassword] = useState('');

  const handleClose = useCallback(() => {
    setEnteredPassword(``);
    onClose && onClose();
  }, [setEnteredPassword, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit(enteredPassword);
    handleClose();
  }, [onSubmit, enteredPassword, handleClose]);

  return (
    <Modal visible={visible} onClose={onClose}>
      <View className='w-[380px]'>
        <Text className='font-[Lato-Bold] text-lg text-center'>{title}</Text>
        {generalMessage && (
          <Text
            className={`mt-[17px] mb-[19px] font-[Lato-Regular] text-xs text-center text-[#20293C] ${
              error && 'text-[#a1403e]'
            }`}>
            {generalMessage}
          </Text>
        )}
        <Text
          className={`mt-[7px] font-[Lato-Regular] text-xs text-center text-[#20293C] ${
            error && 'text-[#a1403e]'
          }`}>
          {passwordMessage}
        </Text>
        <PasswordInput
          className='my-4 mx-2'
          placeholder='Enter your password'
          placeholderTextColor='#a8a8a8'
          onChangeText={setEnteredPassword}
          onSubmitEditing={() => {
            enteredPassword.length > 0 && handleSubmit();
          }}
          value={enteredPassword}
          error={error}
        />
        <View className='flex-row items-center justify-center w-full'>
          <TouchableOpacity
            testID='modal-cancel-button'
            className='w-1/4 mx-3'
            onPress={handleClose}>
            <View className='rounded-[4px] py-1 border border-[#ff3367] bg-white'>
              <Text className='font-[Lato-Bold] text-sm text-[#ff3367] text-center'>Cancel</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            testID='modal-submit-button'
            disabled={enteredPassword.length === 0}
            className='w-1/4 mx-3'
            onPress={handleSubmit}>
            <View
              className={`rounded-[4px] py-1 ${
                enteredPassword.length === 0
                  ? 'bg-[#e0b4b4] border border-[#e0b4b4]'
                  : 'bg-[#ff3367] border border-[#ff3367]'
              }`}>
              <Text className='font-[Lato-Bold] text-sm text-white text-center'>{submit}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ExitModal;
