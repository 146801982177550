import React, { useCallback, useState } from 'react';
import { StyleProp, TextInput, View, ViewStyle } from 'react-native';

import SearchIcon from '../../assets/icons/search.svg';

interface Props {
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  placeholder?: string;
  placeholderTextColor?: string;
  value?: string;
  onChangeText?: (value: string) => void;
}

const SearchInput: React.FC<Props> = ({
  style,
  placeholder = '',
  placeholderTextColor = '#aaa',
  value,
  onChangeText,
}) => {
  const [hasText, setHasText] = useState(false);

  const handleTextChange = useCallback(
    (text: string) => {
      const trimmedText = text.trim();
      if (trimmedText.length > 0 && !hasText) {
        setHasText(true);
      } else if (trimmedText.length === 0 && hasText) {
        setHasText(false);
      }
      onChangeText && onChangeText(trimmedText);
    },
    [hasText, setHasText, onChangeText]
  );

  return (
    <View style={style} className='flex-row items-center'>
      <View className='px-2'>
        <SearchIcon
          width={18}
          height={18}
          viewBox='0 0 18 18'
          color={hasText ? '#21232f' : placeholderTextColor}
        />
      </View>
      <TextInput
        testID='search-input'
        accessibilityRole='search'
        accessibilityLabel='Search'
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        value={value}
        textAlignVertical='center'
        textContentType='password'
        onChangeText={handleTextChange}
        autoCorrect={false}
        // TODO: some styles don't work on web right now, like 'outlint-none'
        // That should get fixed once we switch web back to CSS styles
        className={`w-[90%] outline-none ${hasText ? 'font-[Lato-Bold]' : 'font-[Lato-Regular]'}`}
      />
    </View>
  );
};

export default SearchInput;
