import React, { useCallback, useEffect } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import WarningCircleIcon from '../../assets/icons/warning-circle.svg';
import useToast from '../hooks/useToast';
import { Toast as ToastType } from '../models/toast';

interface Props extends ToastType {}

const Toast: React.FC<Props> = ({
  id,
  message,
  dismiss,
  timeout,
  type = 'info',
  visible = true,
}) => {
  const toast = useToast();
  const animation = useSharedValue(0);

  const deleteToast = useCallback(() => {
    toast.hide(id);
  }, [id, toast]);

  const handleClose = useCallback(() => {
    animation.value = withTiming(0, { duration: 250 }, () => runOnJS(deleteToast)());
  }, [animation, deleteToast]);

  useEffect(() => {
    animation.value = withTiming(1, { duration: 250 });
    if (timeout && timeout > 0) {
      const timer = setTimeout(() => handleClose(), timeout);
      return () => clearTimeout(timer);
    }
  }, [animation, handleClose, timeout]);

  useEffect(() => {
    if (!visible) {
      handleClose();
    }
  }, [handleClose, visible]);

  const animationStyle = useAnimatedStyle(() => {
    return {
      opacity: animation.value,
      transform: [{ translateY: interpolate(animation.value, [0, 1], [-20, 0]) }],
    };
  });

  return (
    <Animated.View testID='toast' style={[styles.container, animationStyle]}>
      <View
        testID='toast-message'
        className={`flex-row items-center space-x-2 py-1 sm:py-2 px-2 sm:px-6 mt-1 bg-white border-[#ff3367] rounded-l-md border-b border-l border-t ${
          dismiss ? '' : 'border-r rounded-br-md rounded-t-md'
        }`}>
        {type === 'error' && <WarningCircleIcon width={16} height={16} color='#ff3367' />}
        <Text numberOfLines={3} className='font-[Lato-Bold] text-[#ff3367] text-xs sm:text-sm'>
          {message}
        </Text>
      </View>
      {dismiss && (
        <Pressable
          className='select-none py-1 sm:py-2 px-2 sm:px-6 mt-1 bg-[#ff3367] rounded-r-md'
          testID='toast-button'
          onPress={handleClose}>
          <Text className='font-[Lato-Bold] text-white text-xs sm:text-sm'>{dismiss}</Text>
        </Pressable>
      )}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});

export default Toast;
