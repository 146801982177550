import AsyncStorage from '@react-native-async-storage/async-storage';
import { atomWithStorage, createJSONStorage, selectAtom } from 'jotai/utils';
import type { AsyncStorage as IAsyncStorage } from 'jotai/utils/atomWithStorage';
import { Platform } from 'react-native';

type AuthAtom = {
  isAuthenticated: boolean;
  username: string;
  rememberMe: boolean;
  token?: string;
  org?: string;
  ssoEnabled?: boolean;
  ssoOnly?: boolean;
};

const storage = Platform.select({
  web: undefined,
  default: { ...createJSONStorage(() => AsyncStorage), delayInit: true },
});

const authAtom = atomWithStorage<AuthAtom>(
  'auth',
  {
    isAuthenticated: false,
    username: '',
    rememberMe: false,
    ssoOnly: false,
    ssoEnabled: false,
  },
  storage as IAsyncStorage<AuthAtom>
);

export const isAuthenticatedAtom = selectAtom(authAtom, (a) => a.isAuthenticated);
export const usernameAtom = selectAtom(authAtom, (a) => a.username);
export const ssoAtom = selectAtom(authAtom, (a) => ({
  ssoEnabled: a.ssoEnabled,
  ssoOnly: a.ssoOnly,
}));

export default authAtom;
