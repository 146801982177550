import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';

import { Toast, ToastOptions } from '../models/toast';
import toastAtom from '../store/toastAtom';

const useToast = () => {
  const setToastList = useSetAtom(toastAtom);
  const toastList = useAtomValue(toastAtom);
  const hide = useCallback(
    (id: string) => {
      toastList.find((item) => item.id === id)?.onDismiss?.();
      setToastList((currentList) => currentList.filter((toast) => toast.id !== id));
    },
    [setToastList, toastList]
  );

  const hideAll = useCallback(() => {
    // this needs to hit hit on all of these
    setToastList((currentList) => currentList.map((item) => ({ ...item, visible: false })));
  }, [setToastList]);

  const show = useCallback(
    (message: string, options?: ToastOptions) => {
      const toast: Toast = { id: uuidv4(), message, visible: true, ...options };
      setToastList((currentList) => [...currentList, toast]);
      return toast.id;
    },
    [setToastList]
  );

  return { show, hide, hideAll };
};

export default useToast;
