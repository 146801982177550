import {
  configure as arConfigure,
  getLicense as arGetLicense,
  Color,
  License,
} from '@mersive/active-routing';
import { useEffect, useState } from 'react';

import useSpace from './useSpace';

import { DisplayModel } from '../models/display';

const getLicense = async (ip: string): Promise<License> => {
  if (__DEV__) {
    const license: License = {
      trial: new Date(),
      subscription: new Date(),
      licensed: true,
    };

    return license;
  }

  try {
    return arGetLicense(ip);
  } catch (error) {
    throw new Error(`Failed to retrieve License: ${error}`);
  }
};

const configure = async (ip: string, label: string, color: Color): Promise<void> => {
  if (__DEV__) return;

  try {
    return arConfigure(ip, {
      presence: true,
      message: `${label} is sharing`,
      background: color,
      foreground: '#ffffff',
    });
  } catch (error) {
    throw new Error(`Failed to Configure: ${error}`);
  }
};

type DisplayData = DisplayModel & {
  license: License | undefined;
};

type DisplayResult = {
  data?: DisplayData;
  status: 'loading' | 'error' | 'offline' | 'success';
  error: string;
};

const useDisplay = (id: string, spaceId: string): DisplayResult => {
  const { data: space, status: spaceStatus, error: spaceError } = useSpace(spaceId);

  const [status, setStatus] = useState<'success' | 'offline' | 'error' | 'loading'>('loading');
  const [data, setData] = useState<DisplayData>();
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (spaceStatus !== 'success') {
      setStatus(spaceStatus);
      setError(`Couldn't retrieve Display: ${spaceError}`);
      setData(undefined);
    }

    const display = space?.displays.find((d) => d.id === id);
    if (!display) {
      setStatus('error');
      setError(`Unknown Display ${id}`);
      setData(undefined);
      return;
    }

    // Load the state and data to `offline` before we make the api
    // call, so that the Display component has the correct name and color
    // to render.
    setStatus('offline');
    setData({
      ...display,
      license: undefined,
    });

    const fetchData = async () => {
      console.log(`Checking licenses for display id=[${display.label}]`);
      try {
        const license = await getLicense(display.ip);
        console.log(`Sending Configure: display=[${display.label}]`);
        await configure(display.ip, display.label, display.color);
        console.log(`Display Configured: display=[${display.label}]`);
        setError('');
        setStatus('success');
        setData({
          ...display,
          license,
        });
      } catch (e: any) {
        setStatus('offline');
        setError(e.message);
        setData({
          ...display,
          license: undefined,
        });
        console.error(`Failure: display=[${id}]`, e);
      }
    };

    fetchData();
  }, [space, id, spaceStatus, spaceError]);

  return { status, data, error };
};

export default useDisplay;
