import { Color } from '@mersive/active-routing';
import React from 'react';
import { View } from 'react-native';

import Icon from '../../assets/icons/display.svg';

interface Props {
  colors?: Color[];
}

const DisplayIcon: React.FC<Props> = ({ colors = [] }) => {
  const colWidth = colors.length > 1 ? '50%' : '100%';
  const rowHeight = `${(1 / Math.floor((colors.length + 1) / 2)) * 100}%`;

  return (
    <View className='flex-1 justify-center'>
      <View style={{ aspectRatio: 24 / 20 }} className='max-w-full max-h-full relative'>
        <Icon width='100%' height='100%' viewBox='0 0 24 20' color='#f2f2f2' />
        {colors.length > 0 && (
          <View className='flex-row flex-wrap absolute top-[10%] left-[10%] w-[80%] h-[70%]'>
            {colors.map((color, i) => (
              <View
                key={i}
                style={{ backgroundColor: color, width: colWidth, height: rowHeight }}
              />
            ))}
          </View>
        )}
      </View>
    </View>
  );
};

export default DisplayIcon;
