import React from 'react';
import { Text, View } from 'react-native';

import Screen from '../components/Screen';
import { RootStackScreenProps } from '../navigation/types';

interface Props extends RootStackScreenProps<'Help'> {}

const HelpScreen: React.FC<Props> = () => {
  return (
    <Screen>
      <View className='flex-1 justify-center items-center'>
        <Text>Help Screen</Text>
      </View>
    </Screen>
  );
};

export default HelpScreen;
