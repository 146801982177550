import { Color } from '@mersive/active-routing';

import { CustomPreset } from '../models/customPreset';
import { DisplayModel } from '../models/display';
import { Route } from '../models/route';
import { Space } from '../models/space';

const transformDisplay = (input: any): DisplayModel => {
  return {
    ...input,
  };
};

const transformRoute = (input: any, displays: DisplayModel[]): Route => {
  const source = displays.find((d) => d.id === input.source);
  const sink = displays.find((d) => d.id === input.sink);
  return {
    source,
    sink,
    color: Color.Gray,
    isActive: false,
  } as Route;
};

const transformCustomPreset = (input: any, displays: DisplayModel[]): CustomPreset => {
  return {
    id: input.id,
    label: input.label,
    routes: input.routes.map((route: any) => transformRoute(route, displays)),
  } as CustomPreset;
};

const transformSpace = (input: any): Space => {
  const displays = input.displays.map((display: any) => transformDisplay(display));
  return {
    id: input.id,
    name: input.name,
    type: input.type,
    buildingName: input.buildingName,
    roomNumber: input.roomNumber,
    displays,
    rows: input.rows,
    columns: input.columns,
    customPresets: input.customPresets.map((preset: any) =>
      transformCustomPreset(preset, displays)
    ),
  } as Space;
};

export { transformDisplay, transformCustomPreset, transformRoute, transformSpace };
