import React from 'react';
import { KeyboardAvoidingView, Pressable, Modal as RNModal, Text, View } from 'react-native';

import DisplayPreview from './DisplayPreview';

import CloseCircleIcon from '../../assets/icons/close-circle.svg';

interface Props {
  name: string;
  color: string;
  address: string;
  visible: boolean;
  onClose?: () => void;
}

const DisplayPreviewModal: React.FC<Props> = ({ name, color, address, visible, onClose }) => {
  return (
    <RNModal
      animationType='slide'
      transparent={true}
      visible={visible}
      onRequestClose={() => {
        onClose?.();
      }}>
      <Pressable
        testID='preview-backdrop'
        className='flex-1 cursor-default'
        onPress={() => {
          onClose?.();
        }}>
        <View className='flex-1 items-center justify-center select-none'>
          <KeyboardAvoidingView behavior='position'>
            <Pressable onPress={() => {}} className='cursor-default'>
              <View
                testID='preview-window'
                style={{ backgroundColor: color }}
                className='flex-col rounded-lg shadow-sm shadow-black/50 max-w-[85vw] max-h-[90vh] p-1'>
                <View
                  style={{ backgroundColor: color }}
                  className='w-full h-7 justify-center items-center'>
                  <Text className='font-[Lato-Bold] text-lg text-white'>{name}</Text>
                  <Pressable
                    className='absolute top-1 right-1 h-4 w-4 hover:opacity-80'
                    onPress={() => {
                      onClose?.();
                    }}>
                    <CloseCircleIcon className='h-full w-full' color='white' />
                  </Pressable>
                </View>
                <View className='rounded-b-lg bg-slate-300 items-center justify-center'>
                  <DisplayPreview address={address} />
                </View>
              </View>
            </Pressable>
          </KeyboardAvoidingView>
        </View>
      </Pressable>
    </RNModal>
  );
};

export default DisplayPreviewModal;
