import { useAtomValue } from 'jotai';
import React from 'react';
import { View } from 'react-native';

import Toast from './Toast';

import toastAtom from '../store/toastAtom';

interface Props {}

const ToastContainer: React.FC<Props> = () => {
  const toastList = useAtomValue(toastAtom);
  //This has an increased z index so it will overlay itself on top of the display grid
  return (
    <View className='absolute self-center items-center z-10 pt-1'>
      {toastList.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </View>
  );
};

export default ToastContainer;
