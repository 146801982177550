import React, { useMemo, useState } from 'react';
import { Text, TextInput, TouchableOpacity, View } from 'react-native';

import { validateEmail } from '../utils/email';

interface Props {
  email?: string;
  onBack?: () => void;
  onSubmit?: (email: string) => void;
}

const LoginRecoveryForm: React.FC<Props> = ({ email = '', onBack, onSubmit }) => {
  const [enteredEmail, setEnteredEmail] = useState(email);
  const isEmailValid = useMemo(() => validateEmail(enteredEmail), [enteredEmail]);

  const handleEmailChange = (text: string) => {
    setEnteredEmail(text.trim());
  };

  return (
    <View className='w-full space-y-3'>
      <View className='h-6 items-start justify-center'>
        <TouchableOpacity testID='login-back-button' onPress={() => onBack?.()}>
          <Text className='font-[Lato-Bold] text-sm text-white'>{'< Back'}</Text>
        </TouchableOpacity>
      </View>
      <Text className='font-[Lato-Bold] text-xs text-white'>Enter your e-mail address</Text>
      <TextInput
        testID='email-input'
        accessibilityLabel='Email'
        className='h-9 px-4 mb-4 font-[Lato-Regular] bg-white rounded-[4px] border border-[#D4D4D5] outline-none'
        placeholder='E-mail address'
        placeholderTextColor='#8F9FA5'
        autoComplete='off'
        autoCapitalize='none'
        value={enteredEmail}
        onChangeText={handleEmailChange}
      />
      <TouchableOpacity
        testID='reset-password-button'
        disabled={!isEmailValid}
        onPress={() => {
          onSubmit?.(enteredEmail);
        }}
        className={`h-9 rounded-[4px] justify-center ${
          isEmailValid ? 'bg-[#FF3367]' : 'bg-[#e0b4b4]'
        }`}>
        <Text className='font-[Lato-Bold] text-center text-white'>Reset password</Text>
      </TouchableOpacity>
    </View>
  );
};

export default LoginRecoveryForm;
