import { HeaderHeightContext } from '@react-navigation/elements';
import { NavigationContainer, useNavigation, useRoute } from '@react-navigation/native';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useState } from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  PixelRatio,
  Platform,
  Pressable,
  Text,
  View,
} from 'react-native';
import { gestureHandlerRootHOC } from 'react-native-gesture-handler';

import LinkingConfiguration from './LinkingConfiguration';
import { RootStackParamList } from './types';

import ArrowBackIcon from '../../assets/icons/arrow_back.svg';
import UserIcon from '../../assets/icons/user-circle.svg';
import LogoutModal from '../components/LogoutModal';
import ToastContainer from '../components/ToastContainer';
import UserMenu from '../components/UserMenu';
import useAuthentication from '../hooks/useAuthentication';
import AboutScreen from '../screens/AboutScreen';
import HelpScreen from '../screens/HelpScreen';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';
import NotRecommendedScreen from '../screens/NotRecommendedScreen';
import SpaceScreen from '../screens/SpaceScreen';
import activeRoutesAvailableAtom from '../store/activeRoutesAvailableAtom';
import { isAuthenticatedAtom, ssoAtom } from '../store/authAtom';
import exitSpacePromptAtom from '../store/exitSpacePromptAtom';
import { requireLogoutPasswordAtom, requireSpaceExitPasswordAtom } from '../store/settingsAtoms';

interface Props {}

const Navigation: React.FC<Props> = () => {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <ToastContainer />
      <RootNavigator />
    </NavigationContainer>
  );
};

const Stack = createStackNavigator<RootStackParamList>();

const HeaderLeft = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const activeRoutes = useAtomValue(activeRoutesAvailableAtom);
  const { ssoEnabled } = useAtomValue(ssoAtom);
  const requireSpaceExitPassword = useAtomValue(requireSpaceExitPasswordAtom);
  const setExitSpacePrompt = useSetAtom(exitSpacePromptAtom);

  const handlePress = useCallback(() => {
    if (route.name === 'Space') {
      if ((!requireSpaceExitPassword || ssoEnabled) && !activeRoutes) {
        console.log('Exit space without a prompt');
        if (navigation.canGoBack()) navigation.goBack();
        else navigation.navigate('Home');
        return;
      }

      if (!requireSpaceExitPassword || ssoEnabled) setExitSpacePrompt('simple');
      else setExitSpacePrompt('password');
      console.log('Show exit prompt');
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Home');
    }
  }, [activeRoutes, navigation, route, requireSpaceExitPassword, setExitSpacePrompt, ssoEnabled]);

  return (
    <Pressable testID='back-button' accessibilityLabel='Back' onPress={handlePress}>
      <View className='flex-row items-center select-none'>
        <View className='w-6 h-6 ml-4 ios:ml-2 android:ml-2 mr-2'>
          <ArrowBackIcon width='100%' height='100%' viewBox='0 0 24 24' color='#f2f2f2' />
        </View>
        <Text className='text-[#f2f2f2] font-[Lato-Bold] text-lg'>
          {route.name === 'Space' ? 'Exit' : 'Back'}
        </Text>
      </View>
    </Pressable>
  );
};

const HeaderRight = () => {
  const navigation = useNavigation();
  const { logout } = useAuthentication();
  const { ssoEnabled } = useAtomValue(ssoAtom);
  const requireLogoutPassword = useAtomValue(requireLogoutPasswordAtom);
  const activeRoutes = useAtomValue(activeRoutesAvailableAtom);
  const [logoutPrompt, setLogoutPrompt] = useState<'none' | 'simple' | 'password'>('none');
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [defaultHeight, setDefaultHeight] = useState(64);

  return (
    <HeaderHeightContext.Consumer>
      {(headerHeight) => {
        return (
          <View
            style={{ marginRight: Platform.select({ web: 24, default: 8 }) }}
            className='select-none h-full justify-center'
            onLayout={(event: LayoutChangeEvent) => {
              setDefaultHeight(event.nativeEvent.layout.height);
            }}>
            <UserMenu
              visible={showUserMenu}
              headerHeight={headerHeight || defaultHeight}
              onOpen={() => setShowUserMenu(true)}
              onClose={() => setShowUserMenu(false)}
              onHelp={() => {
                setShowUserMenu(false);
                navigation.navigate('Help');
              }}
              onAbout={() => {
                setShowUserMenu(false);
                navigation.navigate('About');
              }}
              onLogout={() => {
                setShowUserMenu(false);

                if (requireLogoutPassword && !ssoEnabled) {
                  setLogoutPrompt('password');
                } else if (
                  (ssoEnabled && activeRoutes) ||
                  (!requireLogoutPassword && !ssoEnabled && activeRoutes)
                ) {
                  setLogoutPrompt('simple');
                } else {
                  console.log('Skip prompt');
                  setTimeout(() => {
                    logout();
                    setLogoutPrompt('none');
                  }, 500);
                }
              }}>
              <View className='w-6 h-6'>
                <UserIcon
                  width='100%'
                  height='100%'
                  viewBox='0 0 29 28'
                  color={`${showUserMenu ? '#ff3367' : '#f2f2f2'}`}
                />
              </View>
            </UserMenu>
            <LogoutModal
              promptType={logoutPrompt}
              onClose={() => {
                setLogoutPrompt('none');
              }}
              onSubmit={() => {
                // on iOS, after logout and logging back in, the logout modal
                // is still visible. This hack seems to work for now.
                setTimeout(() => {
                  logout();
                  setLogoutPrompt('none');
                }, 500);
              }}
            />
          </View>
        );
      }}
    </HeaderHeightContext.Consumer>
  );
};

const RootNavigator = () => {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);

  const defaultHeaderLeft = useCallback(() => <HeaderLeft />, []);
  const headerRight = useCallback(() => <HeaderRight />, []);
  const shouldRecommendedShow = useCallback(() => {
    const pixelDensity = PixelRatio.get();
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;
    const adjustedWidth = width * pixelDensity;
    const adjustedHeight = height * pixelDensity;
    //This may return true on Web or electron when the window is smaller than this baseline
    if (pixelDensity < 2 && (adjustedWidth >= 1000 || adjustedHeight >= 1000)) {
      return false;
    } else if (pixelDensity === 2 && (adjustedWidth >= 1824 || adjustedHeight >= 1824)) {
      return false;
    } else {
      return true;
    }
  }, []);
  return (
    <Stack.Navigator
      initialRouteName={
        isAuthenticated ? 'Home' : shouldRecommendedShow() ? 'NotRecommended' : 'Login'
      }
      screenOptions={{
        gestureEnabled: true,
        headerStyle: {
          backgroundColor: '#21232f',
        },
        headerTintColor: '#fff',
        headerTitleStyle: {
          fontFamily: 'Lato-Bold',
          fontSize: 20,
        },
        headerTitleAlign: 'center',
        headerShadowVisible: false,
        headerLeft: defaultHeaderLeft,
        headerRight,
        ...Platform.select({ native: TransitionPresets.SlideFromRightIOS }),
      }}>
      {isAuthenticated ? (
        <>
          <Stack.Screen
            name='Home'
            component={HomeScreen}
            options={{ title: 'Solstice Routing Spaces', headerLeft: () => null }}
          />
          <Stack.Screen
            name='Space'
            component={gestureHandlerRootHOC(SpaceScreen)}
            options={{ title: 'Name of Space', gestureEnabled: false }}
          />
          <Stack.Screen
            name='Help'
            component={gestureHandlerRootHOC(HelpScreen)}
            options={{ title: 'Help' }}
          />
          <Stack.Screen
            name='About'
            component={gestureHandlerRootHOC(AboutScreen)}
            options={{ title: 'About' }}
          />
        </>
      ) : (
        <>
          {shouldRecommendedShow() ? (
            <>
              <Stack.Screen
                name='NotRecommended'
                component={gestureHandlerRootHOC(NotRecommendedScreen)}
                options={{ headerShown: false }}
              />
              <Stack.Screen
                name='Login'
                component={gestureHandlerRootHOC(LoginScreen)}
                options={{ headerShown: false }}
              />
            </>
          ) : (
            <Stack.Screen
              name='Login'
              component={gestureHandlerRootHOC(LoginScreen)}
              options={{ headerShown: false }}
            />
          )}
        </>
      )}
    </Stack.Navigator>
  );
};

export default Navigation;
