import React, { useCallback, useMemo, useState } from 'react';
import { Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';

import SortButton from './SortButton';

import ChevronDownIcon from '../../assets/icons/chevron-down.svg';
import ChevronUpIcon from '../../assets/icons/chevron-up.svg';
import { Space } from '../models/space';

type Category = 'name' | 'type' | 'roomNumber' | 'building';
type Direction = 'up' | 'down' | 'none';

interface Props {
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  spaces: Space[];
  title: string;
  onSpaceSelected?: (space: Space) => void;
}

const SpaceList: React.FC<Props> = ({ style, title, spaces, onSpaceSelected }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [sort, setSort] = useState({
    category: 'name' as Category,
    direction: 'none' as Direction,
  });

  const sortedSpaces = useMemo(() => {
    if (sort.direction === 'none') {
      return spaces;
    }
    const sorted = [...spaces].sort((a, b) => {
      switch (sort.category) {
        case 'name':
          return b.name.localeCompare(a.name);
        case 'type':
          return b.type.localeCompare(a.type);
        case 'roomNumber':
          return b.roomNumber.localeCompare(a.roomNumber);
        case 'building':
          return b.buildingName.localeCompare(a.buildingName);
        default:
          return 0;
      }
    });
    return sort.direction === 'up' ? sorted : sorted.reverse();
  }, [spaces, sort]);

  const handleSort = useCallback(
    (category: Category) => {
      if (sort.category === category) {
        if (sort.direction === 'none') {
          setSort({ category, direction: 'down' });
        } else if (sort.direction === 'down') {
          setSort({ category, direction: 'up' });
        } else {
          setSort({ category, direction: 'none' });
        }
      } else {
        setSort({ category, direction: 'down' });
      }
    },
    [sort]
  );

  if (spaces.length === 0) {
    return null;
  }

  return (
    <View style={style} className={`w-full rounded-md border-2 sm:border-[3px] border-gray-400`}>
      <Pressable
        testID='space-list-toggle-button'
        className={`flex-row items-center rounded-t-sm sm:rounded-t-md ${
          !collapsed && 'bg-[#21232f]'
        }`}
        onPress={() => setCollapsed(!collapsed)}>
        <View style={{ aspectRatio: 20 / 13 }} className='m-3 w-3 sm:w-5 justify-center'>
          {collapsed ? (
            <ChevronDownIcon width='100%' height='100%' viewBox='0 0 20 13' color='#ff3367' />
          ) : (
            <ChevronUpIcon width='100%' height='100%' viewBox='0 0 20 13' color='#ff3367' />
          )}
        </View>
        <Text className='font-[Lato-Regular] text-sm sm:text-lg text-white'>{title}</Text>
      </Pressable>
      <View className={`${collapsed && 'hidden'}`}>
        <View className='py-2 px-2 sm:px-7 sm:flex flex-row items-center border-y-2 sm:border-y-[3px] border-gray-400'>
          <SortButton
            className='w-4/12 pr-1 sm:pr-4'
            direction={sort.category === 'name' ? sort.direction : 'none'}
            onPressed={() => handleSort('name')}>
            <Text className='font-[Lato-Bold] text-sm sm:text-lg text-white'>Name</Text>
          </SortButton>
          <SortButton
            className='w-3/12 pr-1 sm:pr-4'
            direction={sort.category === 'type' ? sort.direction : 'none'}
            onPressed={() => handleSort('type')}>
            <Text className='font-[Lato-Bold] text-sm sm:text-lg text-white'>Type</Text>
          </SortButton>
          <SortButton
            className='w-3/12 pr-1 sm:pr-4'
            direction={sort.category === 'building' ? sort.direction : 'none'}
            onPressed={() => handleSort('building')}>
            <Text className='font-[Lato-Bold] text-sm sm:text-lg text-white'>Building</Text>
          </SortButton>
          <SortButton
            className='w-2/12 pr-1 sm:pr-4'
            direction={sort.category === 'roomNumber' ? sort.direction : 'none'}
            onPressed={() => handleSort('roomNumber')}>
            <Text className='font-[Lato-Bold] text-sm sm:text-lg text-white'>Room</Text>
          </SortButton>
        </View>
        {sortedSpaces.map((space) => {
          const isLast = space.id === sortedSpaces[spaces.length - 1].id;
          return (
            <Pressable
              testID='space-list-item-button'
              accessibilityLabel={space.name}
              key={space.id}
              className={`select-none flex-row py-3 px-2 sm:px-7 items-center ${
                isLast ? 'border-none' : 'border-b border-gray-400'
              }`}
              onPress={() => onSpaceSelected && onSpaceSelected(space)}>
              <View className='w-4/12 pr-4'>
                <Text className='font-[Lato-Regular] text-sm sm:text-lg text-white'>
                  {space.name}
                </Text>
              </View>
              <View className='w-3/12 pr-4'>
                <Text className='font-[Lato-Regular] text-sm sm:text-lg text-white'>
                  {space.type === 'single' ? 'Single Room' : 'Multi-Room'}
                </Text>
              </View>
              <View className='w-3/12 pr-4'>
                <Text className='font-[Lato-Regular] text-sm sm:text-lg text-white'>
                  {space.buildingName}
                </Text>
              </View>
              <View className='w-2/12'>
                <Text className='font-[Lato-Regular] text-sm sm:text-lg text-white'>
                  {space.roomNumber}
                </Text>
              </View>
            </Pressable>
          );
        })}
      </View>
    </View>
  );
};

export default SpaceList;
