import { useAtom } from 'jotai';
import React, { useCallback, useMemo, useState } from 'react';
import { SafeAreaView, ScrollView, Text, TouchableOpacity, View } from 'react-native';

import SearchIcon from '../../assets/icons/search.svg';
import LoadingSpinner from '../components/LoadingSpinner';
import Screen from '../components/Screen';
import SearchInput from '../components/SearchInput';
import SpaceList from '../components/SpaceList';
import useSpaces from '../hooks/useSpaces';
import { Space } from '../models/space';
import { RootStackScreenProps } from '../navigation/types';
import { recentlyUsedSpacesAtom } from '../store/settingsAtoms';

interface Props extends RootStackScreenProps<'Home'> {}

const HomeScreen: React.FC<Props> = ({ navigation }) => {
  const [searchText, setSearchText] = useState('');
  const { data: spaces, status: spacesStatus, error: spacesError } = useSpaces();
  const [recentlyUsedSpaces, setRecentlyUsedSpaces] = useAtom(recentlyUsedSpacesAtom);

  console.log(`Retrieved ${spaces?.length} Spaces`);

  const searchResults = useMemo(() => {
    if (searchText === '') {
      return undefined;
    }

    return spaces?.filter((space) => {
      return space.name.toLowerCase().includes(searchText.toLowerCase());
    });
  }, [searchText, spaces]);

  const handleSpaceSelected = useCallback(
    (space: Space) => {
      setRecentlyUsedSpaces((prev) => {
        const next = [space.id].concat(prev.filter((s) => s !== space.id));
        if (next.length > 3) {
          next.pop();
        }
        return next;
      });
      navigation.navigate('Space', { id: space.id });
    },
    [navigation, setRecentlyUsedSpaces]
  );

  if (spacesStatus === 'loading') {
    return (
      <Screen>
        <View className='flex-1 items-center justify-center space-y-6'>
          <LoadingSpinner size={110} />
          <Text className='font-[Lato-Regular] text-lg text-white'>
            Hang tight! Your spaces are almost ready.
          </Text>
        </View>
      </Screen>
    );
  }

  if (spacesStatus === 'error') {
    console.log('Error retrieving spaces:', spacesError);
    return (
      <Screen>
        <View className='flex-1 items-center justify-center mx-1'>
          <Text className='font-[Lato-Bold] text-xl sm:text-3xl text-white text-center'>
            Looks like there was a problem loading your spaces.{'\n'}Please try again.
          </Text>
          <TouchableOpacity
            testID='retry-button'
            accessibilityRole='button'
            onPress={() => {
              // TODO: implement retry logic once useSpaces is properly implemented
              // to fetch real data. We might not need this if we use react-query.
            }}
            className='w-64 sm:w-96 h-9 mt-16 sm:mt-32 rounded-[4px] justify-center bg-[#FF3367]'>
            <Text className='font-[Lato-Bold] text-center text-white'>Retry</Text>
          </TouchableOpacity>
        </View>
      </Screen>
    );
  }

  return (
    <Screen>
      <SafeAreaView className='flex-1 w-full sm:w-[90%] sm:max-w-5xl self-center items-center'>
        <ScrollView
          className='flex-1 w-full'
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}>
          <View className='flex-row-reverse h-[72px] items-center justify-items-stretch'>
            {spaces.length > 0 ? (
              <SearchInput
                className='hidden sm:flex h-9 w-48 rounded-md bg-white'
                value={searchText}
                onChangeText={setSearchText}
                placeholder='Search...'
                placeholderTextColor='#a8a8a8'
              />
            ) : null}
          </View>
          <View className='mb-8'>
            {searchResults ? (
              searchResults.length === 0 ? (
                <View>
                  <SearchIcon
                    width={73}
                    height={73}
                    color={'#F2F2F2'}
                    preserveAspectRatio={'none'}
                    className='self-center mb-[14px] mt-[150px]'
                  />
                  <Text className='font-[Lato-Regular] text-2xl font-bold text-white self-center mb-[75px]'>
                    No Results
                  </Text>
                  <Text className='font-[Lato-Regular] text-base font-normal text-white self-center'>
                    Please try using different search terms
                  </Text>
                </View>
              ) : (
                <SpaceList
                  title={
                    searchResults.length === 1
                      ? `1 Space Found`
                      : `${searchResults.length} Spaces Found`
                  }
                  spaces={searchResults}
                  onSpaceSelected={handleSpaceSelected}
                />
              )
            ) : spaces.length > 0 ? (
              <>
                <SpaceList
                  className='mb-8'
                  title='Recently Used'
                  spaces={spaces.filter((s) => recentlyUsedSpaces.includes(s.id))}
                  onSpaceSelected={handleSpaceSelected}
                />
                <SpaceList
                  title='All Spaces'
                  spaces={spaces!}
                  onSpaceSelected={handleSpaceSelected}
                />
              </>
            ) : (
              <View>
                <Text className='font-[Lato-Regular] text-2xl font-bold text-white self-center mt-[200px] mb-[75px]'>
                  Looks like you don’t have any existing spaces.
                </Text>
                <Text className='font-[Lato-Regular] text-base font-normal text-white self-center'>
                  Contact an admin to create a space and bring Solstice Routing to life.
                </Text>
              </View>
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    </Screen>
  );
};

export default HomeScreen;
