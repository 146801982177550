import mixpanel, { Dict } from 'mixpanel-browser';
import { useCallback } from 'react';

let wasInited = false;

const initMixpanel = () => {
  try {
    if (process.env.MIXPANEL_PROJECT_TOKEN) {
      mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN!);
      wasInited = true;
      console.log(`Initialized MixPanel`);
    }
  } catch (error) {
    console.error('Failed to initialize MixPanel:', error);
  }
};

const useAnalytics = () => {
  if (!wasInited && process.env.MIXPANEL_PROJECT_TOKEN) {
    initMixpanel();
  }

  const trackEvent = useCallback((event: string, extraData?: Dict) => {
    if (!wasInited) {
      return;
    }

    try {
      console.log('tracking event:', event);
      mixpanel.track(event, extraData);
    } catch (error) {
      console.error('Failed to track web MixPanel event:', error);
    }
  }, []);

  return { trackEvent };
};

export default useAnalytics;
