import React, { useEffect } from 'react';
import { Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

interface Props {
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  value: boolean;
  onValueChange: () => void;
}

const Switch: React.FC<Props> = ({ value, onValueChange }) => {
  const offset = useSharedValue(value ? 30 : 0);
  const bgColor = useSharedValue(value ? '#FF3367' : '#767577');

  useEffect(() => {
    offset.value = withTiming(value ? 30 : 0);
    bgColor.value = withTiming(value ? '#FF3367' : '#767577');
  }, [value, offset, bgColor]);

  const animatedOffset = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offset.value }],
    };
  });

  const animatedBackground = useAnimatedStyle(() => {
    return {
      backgroundColor: bgColor.value,
    };
  });

  return (
    <Pressable
      onPress={onValueChange}
      accessibilityRole='switch'
      accessibilityState={{ selected: value }}>
      <Animated.View style={animatedBackground} className='h-5 w-[50px] rounded-full'>
        <Animated.View style={[styles.circle, animatedOffset]} />
      </Animated.View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  circle: {
    height: 21,
    width: 21,
    bottom: 0.5,
    borderRadius: 10.5,
    backgroundColor: '#EAEAEA',
    borderColor: '#D4D4D5',
    borderWidth: 1,
  },
});

export default Switch;
