import React, { useCallback, useState } from 'react';
import { Pressable, SafeAreaView, Text, View } from 'react-native';

import CustomMessageModal from './CustomMessageModal';
import SendMessageMenu from './SendMessageMenu';
import SpacePresetsMenu from './SpacePresetsMenu';
import SpaceSettingsMenu from './SpaceSettingsMenu';

import AllToPrimaryOutlineIcon from '../../assets/icons/all_to_primary_outline.svg';
import AllToPrimarySolidIcon from '../../assets/icons/all_to_primary_solid.svg';
import CustomPresetOutlineIcon from '../../assets/icons/custom_preset_outline.svg';
import CustomPresetSolidIcon from '../../assets/icons/custom_preset_solid.svg';
import GearOutlineIcon from '../../assets/icons/gear_outline.svg';
import GearSolidIcon from '../../assets/icons/gear_solid.svg';
import MessageOutlineIcon from '../../assets/icons/message_outline.svg';
import MessageSolidIcon from '../../assets/icons/message_solid.svg';
import PrimaryToAllOutlineIcon from '../../assets/icons/primary_to_all_outline.svg';
import PrimaryToAllSolidIcon from '../../assets/icons/primary_to_all_solid.svg';
import ResetIcon from '../../assets/icons/reset.svg';
import { CustomPreset } from '../models/customPreset';

interface Props {
  presets: CustomPreset[];
  savePresetPromptVisible: boolean;
  onPrimaryToAll?: () => void;
  onAllToPrimary?: () => void;
  onResetRoutes?: () => void;
  onSavePreset?: () => void;
  onActivatePreset?: (presetId: string) => void;
  onSendMessage?: (message: string) => void;
}

const ActionBar: React.FC<Props> = ({
  presets,
  savePresetPromptVisible,
  onPrimaryToAll,
  onAllToPrimary,
  onResetRoutes,
  onActivatePreset,
  onSavePreset,
  onSendMessage,
}) => {
  const [gearMenuVisible, setGearMenuVisible] = useState(false);
  const [sendMessageMenuVisible, setSendMessageMenuVisible] = useState(false);
  const [sendMessageModalVisible, setSendMessageModalVisible] = useState(false);
  const [presetsMenuVisible, setPresetsMenuVisible] = useState(false);

  const handleActivatePreset = useCallback(
    (presetId: string) => {
      setPresetsMenuVisible(false);
      onActivatePreset?.(presetId);
    },
    [setPresetsMenuVisible, onActivatePreset]
  );

  const handleSavePreset = useCallback(() => {
    setPresetsMenuVisible(false);
    onSavePreset?.();
  }, [setPresetsMenuVisible, onSavePreset]);

  const handlePrimaryToAll = useCallback(() => {
    onResetRoutes?.();
    onPrimaryToAll?.();
  }, [onResetRoutes, onPrimaryToAll]);

  const handleAllToPrimary = useCallback(() => {
    onResetRoutes?.();
    onAllToPrimary?.();
  }, [onResetRoutes, onAllToPrimary]);

  return (
    <SafeAreaView
      testID='action-bar'
      className='bg-gray-300 w-full items-center justify-between flex-row'>
      <View className='w-[19%] flex-row justify-center'>
        <Pressable
          testID='primary-to-all-button'
          className='my-1 py-1 select-none items-center flex-row group border-2 border-transparent hover:border-b-[#FF3367]'
          onPress={handlePrimaryToAll}>
          <View className='flex group-active:hidden'>
            <PrimaryToAllOutlineIcon width={26} height={22} viewBox='0 0 26 22' color='#21232F' />
          </View>
          <View className='hidden group-active:flex'>
            <PrimaryToAllSolidIcon width={26} height={22} viewBox='0 0 26 22' color='#FF3367' />
          </View>
          <Text className='hidden sm:flex text-[#21232F] group-active:text-[#FF3367] text-base font-[Lato-Regular] ml-[10px]'>
            Primary to All
          </Text>
        </Pressable>
      </View>
      <View className='w-[19%] flex-row justify-center'>
        <Pressable
          testID='all-to-primary-button'
          className='my-1 py-1 select-none items-center flex-row group border-2 border-transparent hover:border-b-[#FF3367]'
          onPress={handleAllToPrimary}>
          <View className='flex group-active:hidden'>
            <AllToPrimaryOutlineIcon width={26} height={22} viewBox='0 0 26 22' color='#21232F' />
          </View>
          <View className='hidden group-active:flex'>
            <AllToPrimarySolidIcon width={26} height={22} viewBox='0 0 26 22' color='#FF3367' />
          </View>
          <Text className='hidden sm:flex text-[#21232F] group-active:text-[#FF3367] text-base font-[Lato-Regular] ml-[10px]'>
            All to Primary
          </Text>
        </Pressable>
      </View>
      <View className='w-[19%] flex-row justify-center'>
        <SpacePresetsMenu
          visible={presetsMenuVisible}
          presets={presets}
          onOpen={() => setPresetsMenuVisible(true)}
          onClose={() => {
            setPresetsMenuVisible(false);
          }}
          onPreset={handleActivatePreset}
          onSave={handleSavePreset}>
          <View className='my-1 py-1 select-none items-center flex-row border-2 border-transparent hover:border-b-[#FF3367]'>
            <View className={`${(presetsMenuVisible || savePresetPromptVisible) && 'hidden'}`}>
              <CustomPresetOutlineIcon width={23} height={22} viewBox='0 0 23 22' color='#21232F' />
            </View>
            <View className={`${!presetsMenuVisible && !savePresetPromptVisible && 'hidden'}`}>
              <CustomPresetSolidIcon width={23} height={22} viewBox='0 0 23 22' color='#FF3367' />
            </View>
            <Text
              className={`${
                presetsMenuVisible || savePresetPromptVisible ? 'text-[#FF3367]' : 'text-[#21232F]'
              } text-base font-[Lato-Regular] ml-[10px] hidden sm:flex`}>
              Custom Presets
            </Text>
          </View>
        </SpacePresetsMenu>
      </View>
      <View className='w-[19%] flex-row justify-center'>
        <Pressable
          testID='reset-routes-button'
          className='my-1 py-1 select-none items-center flex-row group border-2 border-transparent hover:border-b-[#FF3367]'
          onPress={onResetRoutes}>
          <View className='flex group-active:hidden'>
            <ResetIcon width={26} height={22} viewBox='0 0 26 22' color='#21232F' />
          </View>
          <View className='hidden group-active:flex'>
            <ResetIcon width={26} height={22} viewBox='0 0 26 22' color='#FF3367' />
          </View>
          <Text className='hidden sm:flex text-[#21232F] group-active:text-[#FF3367] text-base font-[Lato-Regular] ml-[10px]'>
            Reset Routes
          </Text>
        </Pressable>
      </View>
      <View className='w-[19%] flex-row justify-center'>
        <SendMessageMenu
          visible={sendMessageMenuVisible}
          onOpen={() => setSendMessageMenuVisible(true)}
          onClose={() => {
            setSendMessageMenuVisible(false);
          }}
          onEyesUpFront={() => {
            onSendMessage && onSendMessage('Eyes up front');
            setSendMessageMenuVisible(false);
          }}
          onCustomMessage={() => {
            setSendMessageMenuVisible(false);
            setSendMessageModalVisible(true);
          }}>
          <Pressable
            testID='send-message-button'
            className='my-1 py-1 select-none items-center flex-row group border-2 border-transparent hover:border-b-[#FF3367]'
            onPress={() => setSendMessageMenuVisible(!sendMessageMenuVisible)}>
            <View className={`${(sendMessageMenuVisible || sendMessageModalVisible) && 'hidden'}`}>
              <MessageOutlineIcon width={22} height={22} viewBox='0 0 22 22' color='#21232F' />
            </View>
            <View className={`${!sendMessageMenuVisible && !sendMessageModalVisible && 'hidden'}`}>
              <MessageSolidIcon width={22} height={22} viewBox='0 0 22 22' color='#FF3367' />
            </View>
            <Text
              className={`${
                sendMessageMenuVisible || sendMessageModalVisible
                  ? 'text-[#FF3367]'
                  : 'text-[#21232F]'
              } text-base font-[Lato-Regular] ml-[10px] hidden sm:flex`}>
              Send a Message
            </Text>
            <CustomMessageModal
              visible={sendMessageModalVisible}
              onClose={() => setSendMessageModalVisible(false)}
              onSubmit={onSendMessage}
            />
          </Pressable>
        </SendMessageMenu>
      </View>
      <View className='hidden sm:flex w-[5%] flex-row justify-center'>
        <SpaceSettingsMenu
          visible={gearMenuVisible}
          onOpen={() => setGearMenuVisible(true)}
          onClose={() => setGearMenuVisible(false)}>
          <View className='my-1 py-1 select-none items-center flex-row border-2 border-transparent hover:border-b-[#FF3367]'>
            <View className={`${gearMenuVisible && 'hidden'}`}>
              <GearOutlineIcon width={23} height={22} viewBox='0 0 23 22' color='#21232F' />
            </View>
            <View className={`${!gearMenuVisible && 'hidden'}`}>
              <GearSolidIcon width={22} height={22} viewBox='0 0 22 22' color='#FF3367' />
            </View>
          </View>
        </SpaceSettingsMenu>
      </View>
    </SafeAreaView>
  );
};

export default ActionBar;
