import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

interface Props {
  size: number;
  duration?: number; // in miliseconds
  color?: string;
  backgroundColor?: string;
}

const LoadingSpinner: React.FC<Props> = ({
  size,
  duration = 1000,
  color = '#ff3367',
  backgroundColor = '#ffffff',
}) => {
  const rotation = useSharedValue(0);

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration,
        easing: Easing.linear,
      }),
      -1,
      false
    );
  }, [duration, rotation]);

  const uas = useAnimatedStyle(() => {
    return {
      borderRadius: size / 2,
      borderTopColor: color,
      transform: [
        {
          rotateZ: Math.round(rotation.value) + 'deg',
        },
      ],
    };
  }, [size, color]);

  return (
    <View
      accessibilityRole='progressbar'
      style={{
        width: size,
        height: size,
      }}
      className='justify-center items-center'>
      <View
        style={{
          borderRadius: size / 2,
          borderColor: backgroundColor,
        }}
        className='w-full h-full border-4'
      />
      <Animated.View testID='spinner' style={[styles.spinner, uas]} />
    </View>
  );
};

const styles = StyleSheet.create({
  spinner: {
    width: '100%',
    height: '100%',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderWidth: 4,
    position: 'absolute',
  },
});

export default LoadingSpinner;
