import { Color } from '@mersive/active-routing';
import React, { useCallback } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue } from 'react-native-reanimated';

import DisplayIcon from './DisplayIcon';
import DisplayAudioTooltip from './tooltips/DisplayAudioTooltip';
import DisplayDisconnectedTooltip from './tooltips/DisplayDisconnectedTooltip';
import DisplayPrimaryTooltip from './tooltips/DisplayPrimaryTooltip';
import DisplaySubscriptionToolTip from './tooltips/DisplaySubscriptionToolTip';

import SpeakerIcon from '../../assets/icons/speaker.svg';
import StarIcon from '../../assets/icons/star.svg';
import WarningIcon from '../../assets/icons/warning.svg';
import useDisplay from '../hooks/useDisplay';

const AnimatedView = Animated.createAnimatedComponent(View);

interface Props {
  id: string;
  spaceId: string;
  sourceColors?: Color[];
  disabled?: boolean;
}

const Display: React.FC<Props> = ({ id, spaceId, sourceColors = [], disabled = false }) => {
  const { status: displayStatus, data: display, error: displayError } = useDisplay(id, spaceId);

  const nameFontSize = useSharedValue(12);
  const nameLineSpacing = useSharedValue(13);

  const disconnectedFontSize = useSharedValue(10);
  const disconnectedLineSpacing = useSharedValue(12);

  const borderWidth = useSharedValue(3);
  const borderRadius = useSharedValue(10);

  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { width, height } = event.nativeEvent.layout;
      nameFontSize.value = (12 * width) / 100;
      nameLineSpacing.value = (13 * height) / 100;
      disconnectedFontSize.value = (10 * width) / 100;
      disconnectedLineSpacing.value = (12 * height) / 100;
      borderWidth.value = (3 * width) / 100;
      borderRadius.value = (10 * width) / 100;
    },
    [
      nameFontSize,
      nameLineSpacing,
      disconnectedFontSize,
      disconnectedLineSpacing,
      borderWidth,
      borderRadius,
    ]
  );

  const containerStyle = useAnimatedStyle(() => {
    return {
      borderWidth: borderWidth.value,
      borderRadius: borderRadius.value,
      borderColor: display?.color,
    };
  }, [display]);

  const nameFontStyle = useAnimatedStyle(() => {
    return {
      fontSize: nameFontSize.value,
      lineHeight: nameLineSpacing.value,
    };
  });

  const disconnectedFontStyle = useAnimatedStyle(() => {
    return {
      fontSize: disconnectedFontSize.value,
      lineHeight: disconnectedLineSpacing.value,
    };
  });

  if (displayStatus === 'loading') {
    return (
      <View className='opacity-50 h-full w-full'>
        <AnimatedView
          testID='display'
          accessibilityLabel='Display'
          onLayout={handleLayout}
          style={[styles.container, containerStyle]}>
          <View className='w-[82%] grow self-center justify-center'>
            <Animated.Text
              accessibilityRole='text'
              accessibilityLabel={display?.label}
              testID='display-name'
              numberOfLines={3}
              style={[styles.label, nameFontStyle]}>
              Loading...
            </Animated.Text>
          </View>
          <View className={`h-[20%] w-full self-center`} />
        </AnimatedView>
      </View>
    );
  }

  if (displayStatus === 'error') {
    return (
      <View className={`opacity-50 h-full w-full`}>
        <AnimatedView
          testID='display'
          accessibilityLabel='Display'
          onLayout={handleLayout}
          style={[styles.container, containerStyle]}>
          <View className='w-[82%] grow self-center justify-center'>
            <Animated.Text
              accessibilityRole='text'
              accessibilityLabel={display?.label}
              testID='display-name'
              numberOfLines={3}
              style={[styles.label, nameFontStyle]}>
              {displayError}
            </Animated.Text>
          </View>
          <View className={`h-[20%] w-full self-center`} />
        </AnimatedView>
      </View>
    );
  }

  if (displayStatus === 'offline') {
    return (
      <View className={`opacity-50 h-full w-full`}>
        <AnimatedView
          testID='display'
          accessibilityLabel='Display'
          onLayout={handleLayout}
          style={[styles.container, containerStyle]}>
          <View className='flex-row justify-center items-center h-[20%] px-1 pt-[2px]'>
            <View className='w-[20%]'>
              <DisplayDisconnectedTooltip>
                <WarningIcon height='100%' width='100%' viewBox='0 0 16 16' color='#bf1244' />
              </DisplayDisconnectedTooltip>
            </View>
          </View>

          <View className='w-[82%] grow self-center justify-center'>
            <Animated.Text
              accessibilityRole='text'
              accessibilityLabel={display?.label}
              testID='display-name'
              numberOfLines={3}
              style={[styles.label, nameFontStyle]}>
              {display?.label}
            </Animated.Text>
          </View>
          <View className={`h-[20%] w-full self-center`}>
            <Animated.Text
              accessibilityRole='text'
              accessibilityLabel='disconnected-text'
              testID='disconnected-text'
              style={[styles.disconnectedText, disconnectedFontStyle]}>
              DISCONNECTED
            </Animated.Text>
          </View>
        </AnimatedView>
      </View>
    );
  }
  return (
    <View
      className={`${
        display?.license?.licensed && !disabled ? `opacity-100` : `opacity-50`
      } h-full w-full`}>
      <AnimatedView
        testID='display'
        accessibilityLabel='Display'
        onLayout={handleLayout}
        style={[styles.container, containerStyle]}>
        {display?.license?.licensed ? (
          <View className='flex-row justify-between items-center h-[20%] px-1 pt-[2px]'>
            {display?.doesOutputAudio ? (
              <View className='h-full w-[14%]'>
                <DisplayAudioTooltip>
                  <SpeakerIcon height='100%' width='100%' viewBox='0 0 12 12' color='#f2f2f2' />
                </DisplayAudioTooltip>
              </View>
            ) : undefined}

            {display?.primary ? (
              <View className='h-full w-[14%]'>
                <DisplayPrimaryTooltip>
                  <StarIcon height='100%' width='100%' viewBox='0 0 12 12' color='#f2f2f2' />
                </DisplayPrimaryTooltip>
              </View>
            ) : undefined}
          </View>
        ) : (
          <View className='flex-row justify-center items-center h-[20%] px-1 pt-[2px]'>
            {display?.license?.licensed === false ? (
              <View className='w-[20%]'>
                <DisplaySubscriptionToolTip>
                  <WarningIcon height='100%' width='100%' viewBox='0 0 16 16' color='#bf1244' />
                </DisplaySubscriptionToolTip>
              </View>
            ) : undefined}
          </View>
        )}
        <View className='w-[82%] grow self-center justify-center'>
          <Animated.Text
            accessibilityRole='text'
            accessibilityLabel={display?.label}
            testID='display-name'
            numberOfLines={3}
            style={[styles.label, nameFontStyle]}>
            {display?.label}
          </Animated.Text>
        </View>
        <View className={`h-[35%] w-[30%] self-center`}>
          {display?.license?.licensed ? (
            <View className='h-full w-full' testID='display-icon'>
              <DisplayIcon colors={sourceColors} />
            </View>
          ) : undefined}
        </View>
      </AnimatedView>
    </View>
  );
};

// RNW currently doesn't allow mixing CSS and inline style types on
// animated components. Until then, we'll need to stick to using
// StyleSheet.create() for animated styles where needed.
// ISSUE: https://github.com/marklawlor/nativewind/discussions/259

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#21232f',
    width: '100%',
    height: '100%',
  },
  label: {
    color: 'white',
    fontFamily: 'Lato-Regular',
    textAlign: 'center',
    fontWeight: '700',
  },
  disconnectedText: {
    color: 'white',
    fontFamily: 'Lato-Regular',
    textAlign: 'center',
    fontWeight: '900',
    lineHeight: 12,
    fontSize: 10,
  },
});

export default Display;
