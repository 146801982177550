import React from 'react';
import { Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
  className?: string; // needed for linting purposes
  leftText: string;
  rightText: string;
  leftActive: boolean;
  onToggle: () => void;
}

const TextSwitch: React.FC<Props> = (props: Props) => {
  const textStyle = {
    active: 'text-[#EAEAEA] p-1 font-[Lato-Black]',
    inactive: 'text-[#FF3367] p-1 font-[Lato-Regular]',
  };

  const bgStyle = {
    active: 'bg-[#FF3367]',
    inactive: 'bg-transparent',
  };

  return (
    <Pressable
      className='h-[34px] w-full items-center border border-[#FF3367] rounded'
      style={props.style}
      onPress={() => {
        props.onToggle();
      }}>
      <View className='flex-row h-full w-full'>
        <View
          className={`w-1/2 items-center justify-center ${
            props.leftActive ? bgStyle.active : bgStyle.inactive
          }`}>
          <Text className={props.leftActive ? textStyle.active : textStyle.inactive}>
            {props.leftText}
          </Text>
        </View>
        <View
          className={`w-1/2 items-center justify-center ${
            !props.leftActive ? bgStyle.active : bgStyle.inactive
          }`}>
          <Text className={!props.leftActive ? textStyle.active : textStyle.inactive}>
            {props.rightText}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default TextSwitch;
