export type WebrtcErrorType =
  | 'disabled' // WebRTC is disabled on the device
  | 'capture' // Screen capture failed
  | 'network' // Network error
  | 'unknown'; // Unknown error

export class WebrtcError extends Error {
  type: string;

  constructor(type: WebrtcErrorType) {
    switch (type) {
      case 'disabled':
        super('WebRTC is disabled on the device.\nPlease enable the WebRTC feature.');
        break;
      case 'network':
        super('Network error.\nPlease check your connection and try again.');
        break;
      default:
        super('Unknown error.\nPlease try again.');
    }
    this.name = 'WebrtcError';
    this.type = type;
  }
}
