import { useAtom } from 'jotai';

import mockSpaceAlpha from '../mocks/spaces/alphaActiveLearningLab.json';
import mockSpaceEmpty from '../mocks/spaces/empty.json';
import mockSpaceFullLab from '../mocks/spaces/fullActiveLearningLab.json';
import mockSpaceHalf from '../mocks/spaces/halfSize.json';
import mockSpaceJustin from '../mocks/spaces/justin.json';
import mockSpaceRealistic from '../mocks/spaces/realistic.json';
import { Space } from '../models/space';
import { availableSpacesAtom } from '../store/spacesAtom';
import { transformSpace } from '../utils/transforms';

const SHOULD_USE_MOCK_DATA = true;

const getMockSpaces = (): Space[] => {
  return [
    transformSpace(mockSpaceEmpty),
    transformSpace(mockSpaceHalf),
    transformSpace(mockSpaceAlpha),
    transformSpace(mockSpaceJustin),
    transformSpace(mockSpaceFullLab),
    transformSpace(mockSpaceRealistic),
  ];
};

type SpacesData = {
  data: Space[];
  status: 'success' | 'error' | 'loading';
  error: string;
};

const useSpaces = (): SpacesData => {
  const [spaces, setSpaces] = useAtom(availableSpacesAtom);

  let newSpaces: Space[] = [];

  if (SHOULD_USE_MOCK_DATA && spaces.length === 0) {
    console.debug(`Loading mock spaces`);
    setTimeout(() => {
      newSpaces = getMockSpaces();
      setSpaces(newSpaces);
    }, 3000);

    return { data: [], status: 'loading', error: '' };
  }

  return { data: spaces, status: 'success', error: '' };
};

export default useSpaces;
