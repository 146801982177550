import React, { useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import { Easing, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated';
import { Line } from 'react-native-svg';

import RouteLineDot from './RouteLineDot';

interface Props {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  color: string;
  width: number;
  isActive: boolean;
  onPress?: () => void;
}

const RouteLine: React.FC<Props> = ({ x1, y1, x2, y2, color, width, onPress, isActive }) => {
  const animation = useSharedValue(0);
  const ndots = useMemo(() => {
    const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
    return Math.floor(distance / 150) || 1;
  }, [x1, y1, x2, y2]);

  useEffect(() => {
    if (!isActive) return;

    animation.value = 0;
    animation.value = withRepeat(
      withTiming(1, { duration: 1000 * ndots, easing: Easing.linear }),
      -1,
      false
    );
  }, [isActive, animation, ndots]);

  return (
    <>
      <Line
        id='line'
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={color}
        strokeWidth={width}
        strokeOpacity={isActive ? '1.0' : '0.5'}
      />
      {/* onPress doesn't work on web: https://github.com/react-native-svg/react-native-svg/issues/1483 */}
      {Platform.OS === 'web' ? (
        <Line
          id='pressable-line'
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          stroke='transparent'
          strokeWidth={width * 5}
          // @ts-ignore
          onClick={onPress}
        />
      ) : (
        <Line
          id='pressable-line'
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          stroke='transparent'
          strokeWidth={width * 5}
          onPress={onPress}
        />
      )}
      {isActive &&
        Array.from({ length: ndots }).map((_, i) => (
          <RouteLineDot
            key={i}
            animation={animation}
            dot={i}
            ndots={ndots}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            width={width + 2}
            color={color}
            onPress={onPress}
          />
        ))}
    </>
  );
};

export default RouteLine;
