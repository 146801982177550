import React, { useEffect, useState } from 'react';
import { Image, Text, View } from 'react-native';

import LoadingSpinner from './LoadingSpinner';
import ScreenKeyInput from './ScreenKeyInput';

import { getScreenPreview, needsScreenKey } from '../utils/lookin';

type LookinState = {
  status: 'screenKey' | 'loading' | 'error' | 'success' | 'unknown';
  image?: string;
  screenKey?: string;
  error?: string;
};

interface Props {
  address: string;
}

const DisplayPreview: React.FC<Props> = ({ address }) => {
  const [lookinState, setLookinState] = useState<LookinState>({
    status: 'unknown',
  } as LookinState);

  const { status, screenKey, image, error } = lookinState;

  useEffect(() => {
    const checkScreenKey = async () => {
      try {
        const needsKey = await needsScreenKey(address);
        if (needsKey) {
          setLookinState({
            status: 'screenKey',
          });
        } else {
          setLookinState({
            status: 'loading',
          });
        }
      } catch (err: any) {
        setLookinState({
          status: 'error',
          error: err.message,
        });
      }
    };

    const fetchImage = async (_screenKey?: string) => {
      try {
        const img = await getScreenPreview(address, _screenKey);
        setLookinState({
          status: 'success',
          image: img,
        });
      } catch (err: any) {
        if (err.type === 'screenKey') {
          setLookinState({
            status: 'screenKey',
          });
        } else {
          setLookinState({
            status: 'error',
            error: err.message,
          });
        }
      }
    };

    switch (status) {
      case 'unknown':
        checkScreenKey();
        break;
      case 'loading':
        fetchImage(screenKey);
        break;
      default:
        break;
    }
  }, [status, address, screenKey]);

  if (status === 'unknown' || status === 'loading') {
    return (
      <View className='w-[400px] h-[300px] max-w-[60vw] max-h-[50vh] bg-[#21232f] items-center justify-center'>
        <LoadingSpinner size={128} />
      </View>
    );
  }

  if (status === 'error') {
    return (
      <View className='w-[500px] h-[300px] bg-[#21232f] items-center justify-center'>
        <Text className='font-[Lato-Bold] text-white text-xl text-center'>{error}</Text>
      </View>
    );
  }

  if (status === 'screenKey') {
    return (
      <View className='w-[400px] h-[300px] bg-[#21232f] items-center justify-center'>
        <Text className='absolute top-10 font-[Lato-Bold] text-lg text-white'>
          Please insert screen key:
        </Text>
        <ScreenKeyInput
          count={4}
          autoFocus
          onSubmit={(key) => {
            console.log('KEY:', key);
            setLookinState({
              status: 'loading',
              screenKey: key,
            });
          }}
        />
      </View>
    );
  }

  return (
    <View
      style={{ aspectRatio: 16 / 9 }}
      className='w-[80vw] bg-[#21232f] items-center justify-center'>
      <Image resizeMode='contain' className='w-full h-full' source={{ uri: image }} />
    </View>
  );
};

export default DisplayPreview;
