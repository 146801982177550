import React, { useCallback } from 'react';
import { Pressable, Text, View } from 'react-native';

import InfoCircleIcon from '../../assets/icons/info-circle.svg';
import SolsticeIcon from '../../assets/images/solstice_routing_logo_white.svg';
import Screen from '../components/Screen';
import { RootStackScreenProps } from '../navigation/types';

interface Props extends RootStackScreenProps<'NotRecommended'> {}

const NotRecommendedScreen: React.FC<Props> = ({ navigation }) => {
  const handleClose = useCallback(() => {
    navigation.navigate('Login');
  }, [navigation]);
  return (
    <Screen>
      <View className='flex-1 justify-evenly self-center items-center w-11/12  max-w-sm'>
        <View className='w-full items-center justify-end'>
          <View style={{ aspectRatio: 1000 / 264 }} className='w-11/12 max-w-sm max-h-36'>
            <SolsticeIcon width='100%' height='100%' viewBox='0 0 1000 264' />
          </View>
        </View>
        <View className='flex-row items-center'>
          <View>
            <InfoCircleIcon className='' viewBox='0 0 20 20' color='#FF3367' />
          </View>
          <Text className='ml-2 font-[Lato-Bold] text-white text-lg text-center'>
            Device not recommended
          </Text>
        </View>
        <Text className='font-[Lato-Regular] text-base text-white text-center'>
          We recommend using a tablet for the best experience Solstice Routing has to offer.
        </Text>
        <Pressable
          className='py-1 sm:py-2 px-2 sm:px-6 mt-1 bg-[#ff3367] rounded-md items-center justify-center w-full'
          onPress={handleClose}>
          <Text className='font-[Lato-Bold] text-white text-sm'>Dismiss</Text>
        </Pressable>
      </View>
    </Screen>
  );
};

export default NotRecommendedScreen;
