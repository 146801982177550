import React, { useCallback, useState } from 'react';
import { Text, TextInput, TouchableOpacity, View } from 'react-native';

import Modal, { ModalProps } from './Modal';

interface Props extends ModalProps {
  onSubmit?: (presetName: string) => void;
  currentPresetCount: number;
  maxPresetCount: number;
}

const SaveCustomPresetsModal: React.FC<Props> = ({
  visible,
  onClose,
  onSubmit,
  currentPresetCount,
  maxPresetCount,
}) => {
  const [presetName, setPresetName] = useState('');

  const maxMessageLength = 50;

  const hasText = () => {
    return presetName.trim().length > 0;
  };

  const handleClose = useCallback(() => {
    setPresetName(``);
    onClose && onClose();
  }, [setPresetName, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit(presetName);
    handleClose();
  }, [onSubmit, presetName, handleClose]);

  return (
    <Modal visible={visible} onClose={handleClose}>
      <View className='w-96'>
        <Text className='mb-2 font-[Lato-Bold] text-lg text-center'>
          {'Save custom routing preset'}
        </Text>
        <Text
          testID='preset-modal-message'
          className='mb-4 mx-[7px] font-[Lato-Regular] text-[11px] text-[#20293C]'>
          {`Once saved, only an admin can edit or remove this custom preset.\nThis space is using `}
          <Text className='font-[Lato-Bold]'>{`${currentPresetCount} of ${maxPresetCount} `}</Text>
          {`custom presets.`}
        </Text>
        <View
          className={`flex-row items-center justify-between rounded-[5px] h-10 border border-[#D4D4D5] bg-white pl-[15px] pr-2 font-[Lato-Regular]`}>
          <TextInput
            className={`w-full outline-none text-sm ${
              presetName.length ? 'font-[Lato-Bold]' : 'font-[Lato-Regular]'
            }`}
            testID='preset-input'
            placeholder={'Enter a name'}
            placeholderTextColor={'#A8A8A8'}
            textAlignVertical='center'
            onChangeText={setPresetName}
            value={presetName}
            autoCorrect={true}
            autoFocus={true}
            enablesReturnKeyAutomatically
            maxLength={maxMessageLength}
            returnKeyType={'done'}
            onSubmitEditing={() => {
              hasText() && handleSubmit();
            }}
          />
        </View>
        <Text className='ml-[15px] mt-1 font-[Lato-Regular] text-[11px] text-[#20293C]'>{`${
          maxMessageLength - presetName.length
        }/${maxMessageLength} characters remaining`}</Text>
        <View className='flex-row items-center justify-center w-full mt-3'>
          <TouchableOpacity
            testID='preset-cancel-button'
            className='w-1/4 mx-3'
            onPress={handleClose}>
            <View className='border border-[#ff3367] bg-white rounded-md py-1 w-full'>
              <Text className='font-[Lato-Bold] text-base text-[#ff3367] text-center'>Cancel</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            className='w-1/4 mx-3'
            disabled={!hasText()}
            testID='preset-save-button'
            onPress={handleSubmit}>
            <View
              className={`${
                hasText() ? 'bg-[#ff3367] border-[#ff3367]' : 'bg-[#e0b4b4] border-[#e0b4b4]'
              }' rounded-md py-1`}>
              <Text className='font-[Lato-Bold] text-base text-white text-center'>Save</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default SaveCustomPresetsModal;
